import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useFetch } from '../../hooks/useFetch';
import { Link, useNavigate } from 'react-router-dom';
import { AuthFormContainerPage, ErrorMessage, SubmitFormButton } from '../controls/UIControls';
import { 
        PATTERN_NUMERIC,
        PATTERN_ALPHANUMERIC, 
        MESSAGE_GENERIC_ERROR,
        LABEL_DOCUMENT_TYPE_PLACEHOLDER,
        LABEL_DOCUMENT_PLACEHOLDER,
        MESSAGE_DOCUMENT_NUMERIC_INVALID,
        MESSAGE_DOCUMENT_ALPHANUMERIC_INVALID,
        } 
from '../../assets/constants';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para recuperar contraseña
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface PasswordRecoveryForm
 */
export const PasswordRecoveryForm = () => {
    const numericPattern = PATTERN_NUMERIC;
    const alphanumericPattern = PATTERN_ALPHANUMERIC;

    const numericMessage = MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage = MESSAGE_DOCUMENT_ALPHANUMERIC_INVALID;



    const [fetchData, handleFetch] = useFetch();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const [recoveryError, setRecoveryError] = useState(''); 

    const [docPatternMessage, setDocPatternMessage] = useState({pattern: numericPattern, message: numericMessage} );

    const navigate = useNavigate();

    useEffect(()=> {
        let subs = null;
        if(recoveryError!=='') {
            subs = watch(() => setRecoveryError(''));
        }
        return () => subs?subs.unsubscribe():subs;
    },[recoveryError, watch]);

    const maskEmail = (email) => {
        const [user,domain] = email.split('@');
        const userlen = user.length;
        let masked='';
        for(let i=0;i<userlen;i++) {
            masked+=(i===0 || i === 1 )?user[i]:'*';
        }
        masked+='@'+domain;
        return masked;
    }
    // Handle para enviar correo recuperar contraseña
    const handleRecovery = (formValues) => {
        setRecoveryError('');
        handleFetch({
            url:'users/sendreset',
            method: 'POST', 
            data: formValues.email?
                        {email:formValues.email}:
                        {
                            documentType: formValues.documentType,
                            document:formValues.userDocument
                        }
        })
        .then(r => {
                console.log('FETCH RESULT RECOVERY:',r); 
                navigate('/mensaje-contrasena-enviado', {state:{email:maskEmail(r.email) }});
            },
            error => {
                console.log('FETCH ERROR RECOVERY 1', error);
                if(error.errors) {
                    let e = error.errors[0];
                    setRecoveryError(e.message);
                } else {
                    setRecoveryError( MESSAGE_GENERIC_ERROR);
                }
            }
        );
    }
    // Handle para selección de tipo de documento
    const handleSelectChange = (selectedOption) => {
        setDocPatternMessage(selectedOption.target.value === 'PA'?
                            {pattern: alphanumericPattern, message: alphanumericMessage}:
                            {pattern: numericPattern, message: numericMessage});
    }
    


    return (
        <form onSubmit={ handleSubmit(handleRecovery) }>
            <div className="mb-1">
                <label className='auth__form-label'>{LABEL_DOCUMENT_TYPE_PLACEHOLDER}</label>
                <select 
                    defaultValue=""
                    className={`form-select auth__select 
                                ${errors.documentType?"auth__input--error":""}`}
                    aria-label="Default select example"
                    name='documentType'
                    {...register("documentType", {onChange:handleSelectChange })}
                >
                    <option value="CC">Cédula de Ciudadanía</option>
                    <option value="CE">Cédula de Extranjería</option>
                    <option value="DIE">Documento de Identidad Extranjera</option>
                    <option value="PA">Pasaporte</option>
                    <option value="TI">Tarjeta de Identidad</option>
                    <option value="PEP">Permiso Especial de Permanencia</option>
                </select>
            </div>

            <div className="mb-1">
                <label className='auth__form-label'>Número de documento</label>
                <input 
                    type='text' 
                    name='userDocument'
                    className={`form-control auth__input 
                                ${errors.userDocument?"auth__input--error":""}`}
                    placeholder={LABEL_DOCUMENT_PLACEHOLDER}
                    {...register("userDocument", {
                        pattern: {
                            value: docPatternMessage.pattern,
                            message: docPatternMessage.message
                        } })}
                />
                <ErrorMessage className='auth__errorMessage--2lines' 
                        message={errors.userDocument?errors.userDocument.message:''}/> 
            </div>



            <SubmitFormButton loading = {fetchData.loading} 
                                main_title = {'Restablecer mi contraseña'}
                                wait_title = {'Procesando...'} />
            <div className='text-center'><Link className='text_primary' to="/ingreso">Volver a iniciar sesión</Link></div>
            <ErrorMessage className="mb-1 text-center auth__errorMessage--2lines" message={recoveryError}/> 
            <div className='text-center'>
                ¿Aún no tienes cuenta? <Link className='auth__link' to="/planes">Regístrate</Link>
            </div>
        </form>
    )
}

export const PasswordRecoveryPage = () => {
    return (
        <AuthFormContainerPage title="Restablecer Contraseña" content={<PasswordRecoveryForm /> } />
    )
}