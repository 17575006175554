import React, {useEffect, useState} from 'react'
import 'react-phone-input-2/lib/material.css';
import { useFetch } from '../../hooks/useFetch';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { LandingHeaderB2B } from './LandingHeaderB2B'
import { Footer } from '../footer/Footer';

const landingImages = require.context('../../assets/images', true);

export const LandingFormPage = () => {

    const [data, handleFetch] = useFetch();
    const [showModalMailSending, setShowModalMailSending] = useState(false);
    const [buttonMailSending, setButtonMailSending] = useState(false);
    const [showModalMailSendingError, setShowModalMailSendingError] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [position, setPosition] = useState('');
    const [phone, setPhone] = useState('');
    const [asunto, setAsunto] = useState('');
    const [terms, setTerms] = useState(false);    
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1200);
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        companyname: '',
        position: '',
        phone: '',
        asunto: '',
        terms: false,
      });
    useEffect(() => {
        window.scrollTo(0,0);
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1200);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    const handleInputChange = (e, setFunc) => {
        setFunc(e.target.value);
        setErrors({
            ...errors,
            [e.target.name]: '',
          });
    };
    const navigate = useNavigate();
    // Handle cerrar modal cuando se envía correo electrónico
    const handleCloseModal = () => {
        setShowModalMailSending(false);
        navigate('/empresas');
    }
    // Handle cerrar modal cuando se envía correo electrónico en caso de error
    const handleCloseModalError = () => {
        setShowModalMailSendingError(false);
    }
    // Función para enviar datos de formulario de contacto
    const sendContactRequest = (e) => {
        e.preventDefault();
        let valid = true;
        const newErrors = {};
        if (name.trim() === '') {
          newErrors.name = 'El nombre es obligatorio';
          valid = false;
        }
        if (email.trim() === '') {
          newErrors.email = 'El correo electrónico es obligatorio';
          valid = false;
        }else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'El correo electrónico no es válido';
            valid = false;
        }
        if (companyname.trim() === '') {
          newErrors.companyname = 'El nombre de compañia es obligatorio';
          valid = false;
        }
        if (position.trim() === '') {
          newErrors.position = 'La posición es obligatoria';
          valid = false;
        }
        if (phone.trim() === '') {
          newErrors.phone = 'El telefono es obligatorio';
          valid = false;
        }
        if (phone.trim() === '') {
          newErrors.asunto = 'El Asunto es obligatorio';
          valid = false;
        }
        if (terms == false) {
          newErrors.terms = 'Debe aceptar los términos y condiciones';
          valid = false;
        }
        if (!valid) {
            setErrors(newErrors);
          } else {
            setButtonMailSending(true)
            const response = handleFetch({
                url:'contactus/sendmailb2b',
                method: 'POST', 
                data: {
                    name: name,
                    email: email,
                    companyname: companyname,
                    position: position,
                    phone: phone,
                    asunto: asunto
                }
            });
            response
                .then((r) => {
                    const responsetwo = handleFetch({
                        url:'contactus/requestsb2b',
                        method: 'POST', 
                        data: {
                            name: name,
                            email: email,
                            companyname: companyname,
                            position: position,
                            phone: phone,
                            asunto: asunto
                        }
                    });
                    responsetwo
                        .then((rt) => {
                            navigate('/empresas/gracias');
                            },
                            error => {
                                console.log(error.errors);
                                showModalMailSendingError(true)
                            })
                    },
                    error => {
                        console.log(error.errors);
                        showModalMailSendingError(true)
                    })
          }
        
        
    };
    return (
    <>
        <ModalMailSending handleCloseModal={handleCloseModal} showModalMailSending={showModalMailSending}/>
        <ModalMailSendingError handleCloseModal={handleCloseModalError} showModalMailSendingError={showModalMailSendingError}/>
        <LandingHeaderB2B />
        <div className='container-fluid landing__FormSection py-4'>
        <div className='container my-5 my-sm-2'>
            <div className='row justify-content-center align-items-center'>
                <div className='col-12 col-md-6 p-5 px-sm-2 py-sm-2 d-flex flex-column'>
                    
                    <div className='col-12 col-md-12 col-lg-9 p-5 px-sm-2  mx-auto d-flex flex-column align-items-center'>
                        <div className='col-12 text-center'>
                            <img alt='¿QUIERES QUE HABLEMOS?' src={landingImages('./landingB2B/imgForm.png')} className="img-fluid" width="250" height="100%" />
                        </div>
                        <div className='col-12 landingb2b__title-top-banner'>
                        ¿QUIERES QUE HABLEMOS?
                        </div>
                        <div className='col-12 my-4 my-sm-2 landingb2b__paragraph-text landingb2b__white'>
                        Completa el formulario y te contactaremos en menos de 24 horas para agendar tu consultoría y entregar tu licencia de cortesía.<br /> 
                            <span className="fw-bold">¡Juntos encontraremos la solución perfecta para tus necesidades!</span>
                        </div>
                        <div className='col-12 landingb2b__text-respaldo-ean landingb2b__white d-none d-md-block'>
                            CON EL RESPALDO DE LA UNIVERSIDAD EAN <img alt='EAN UNIVERSIDAD' src={landingImages('./landingB2B/eanunilogo.png')} className="img-fluid" width="120" height="50" />
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='col-12 col-md-12 col-lg-10 landing__FormContent p-5 px-sm-2 d-flex flex-column'>
                        <div className='col-12 mb-4 d-flex justify-content-center align-items-center'>
                            <p className='landingb2b__h3-box'>&#9632;</p> <p className='landingb2b__h3 text-center'>AGENDAR MI CONSULTORÍA</p> <p className='landingb2b__h3-box'>&#9632;</p>
                        </div>
                        <form onSubmit={sendContactRequest}></form>
                        <div className="input-container mt-3">
                            <input type="text" id="name" name="name" className="input-container-input" 
                                value={name} onChange={(e) => handleInputChange(e, setName)} required />
                            <label htmlFor="name" className="input-container-label">Nombre completo <span className='auth__required-input'>*</span></label>
                        </div>
                        <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.name}</label></div>
                        <div className="input-container">
                            <input type="email" id="email" className="input-container-input" 
                                value={email} onChange={(e) => handleInputChange(e, setEmail)} required />
                            <label htmlFor="email" className="input-container-label">Email corporativo <span className='auth__required-input'>*</span></label>
                        </div>
                        <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.email}</label></div>
                        <div className="input-container">
                            <input type="text" id="companyname" name="companyname" className="input-container-input" 
                                value={companyname} onChange={(e) => handleInputChange(e, setCompanyname)} required />
                            <label htmlFor="companyname" className="input-container-label">Nombre de la empresa <span className='auth__required-input'>*</span></label>
                        </div>
                        <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.companyname}</label></div>
                        <div className="input-container">
                            <select name="position" id="position" className="input-container-input" value={position} onChange={(e) => handleInputChange(e, setPosition)} required>
                                <option value="" selected></option>
                                <option value="CEO">CEO</option>
                                <option value="Gerente">Gerente</option>
                                <option value="Director">Director</option>
                                <option value="Ejecutivo">Ejecutivo</option>
                                <option value="Analista">Analista</option>
                                <option value="Otro">Otro</option>
                            </select>
                            <label htmlFor="position" className="input-container-label">Cargo <span className='auth__required-input'>*</span></label>
                        </div>
                        <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.position}</label></div>
                        <div className="input-container">
                            <input type="tel" id="phone" className="input-container-input" 
                                value={phone} onChange={(e) => handleInputChange(e, setPhone)} required />
                            <label htmlFor="phone" className="input-container-label">Teléfono <span className='auth__required-input'>*</span></label>
                        </div>
                        <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.phone}</label></div>
                        <div className="input-container mt-3">
                            <select name="asunto" id="asunto" className="input-container-input" value={asunto} onChange={(e) => handleInputChange(e, setAsunto)} required>
                                <option value="" selected></option>
                                <option value="Formación a la medida">Formación a la medida</option>
                                <option value="Proveedores de contenido">Proveedores de contenido</option>
                                <option value="Licencias">Licencias</option>
                                <option value="Fábrica de contenidos">Fábrica de contenidos</option>
                                <option value="Otro">Otro</option>
                            </select>
                            <label htmlFor="asunto" className={`input-container-label`}>Servicio de interés</label>
                        </div>
                        <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.asunto}</label></div>
                        <div className="d-flex justify-content-center align-items-center check-terms mt-3">
                            <input className="form-check-input-b2bform auth__form-check-b2bform" type="checkbox" 
                                value={terms} onChange={(e) => handleInputChange(e, setTerms)} required  />
                            <label className='form-check-label auth__form-label' style={{marginLeft: "10px", fontSize: "12px"}} >
                                Al enviar el formulario, aceptas los <a href='https://eanx.co/terminos-condiciones.pdf' className='auth__link'>Términos y Condiciones</a> y Políticas de privacidad de EanX
                            </label>
                        </div>
                        <div className="alert-erros-b2b"><label className='form-check-label auth__form-label auth__required-input' style={{marginLeft: "10px", fontSize: "12px"}} >{errors.terms}</label></div>
                        <div className='row justify-content-center text-center mt-5 mt-sm-2'>
                            <button type="submit" disabled={buttonMailSending} className='col-12 landingb2b__button-primary px-5 py-3 border border-white' onClick={sendContactRequest}>
                                ENVIAR FORMULARIO</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}
// Función para mostrar mensaje exitoso en Modal de envío de solicitud
const ModalMailSending = ({showModalMailSending, handleCloseModal}) =>{

    return(
        <Modal
            open={showModalMailSending}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./icons/Nuestros-Expertos.png')} style={{width: "65px", marginTop: "25px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Solicitud enviada</div>
                        <p className='ProfilePage__modal-text-description'>
                            Hemos recibido tu solicitud y estamos trabajando en ella, en cuanto tengamos una respuesta, nos comunicaremos contigo. No olvides revisar tu correo electrónico.
                        </p>
                        <div style={{marginTop: "10px", marginBottom: "30px",textAlign: "center"}}>
                            <button
                                className='col-12 landingb2b__button-primary px-5 py-3 border border-white'
                                onClick={handleCloseModal}
                                style={{marginLeft: "0px"}}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
// Función para mostrar mensaje de error en Modal de envío de solicitud
const ModalMailSendingError = ({showModalMailSendingError, handleCloseModal}) =>{

    return(
        <Modal
            open={showModalMailSendingError}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./error.png')} style={{width: "65px", marginTop: "25px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Error al enviar</div>
                        <p className='ProfilePage__modal-text-description'>
                            Ha ocurrido un error al tratar de enviar tu información, por favor intenta nuevamente.
                        </p>
                        <div style={{marginTop: "10px", marginBottom: "30px",textAlign: "center"}}>
                            <button
                                className='col-12 landingb2b__button-primary px-5 py-3 border border-white'
                                onClick={handleCloseModal}
                                style={{marginLeft: "0px"}}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}