import React, { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";

import { PlanBenefitsList } from './PlanBenefits';
import { ErrorMessage, SubmitFormButton, Toast } from '../../controls/UIControls';
import * as CONSTANTS from '../../../assets/constants';
import { apiConfig } from '../../../api/apiConfig';
import { createSplit } from '../../controls/UIControls';
import { useFetch } from '../../../hooks/useFetch';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página contenido de sección edición de planes
 * @interface PlanCreateEditSection
 */
export const PlanCreateEditSection = () => {
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors } } = useForm();
    
    const {authToken}= useAuthToken(true);
    const [fetchData, handleFetch] = useFetch();
    const [benefitsGetter, setBenefitsGetter] = useState(null);
    const [benefits, setBenefits] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [toastProperties, setToastProperties] = useState({show: false});
    const params = new URLSearchParams(window.location.search)
    const [companies, setCompanies] = useState([]);
    const checkboxcertifenabled = watch('canCertificate');
    const checkboxenabled = watch('enabled');
    const checkboxurlenabled = watch('urlenabled');
    const checkboxhighlighted = watch('highlighted');
    const [copyurl, setCopyurl] = useState('');

    const navigate = useNavigate();
    // consulta de endpoint de planes para obtener información de plan individual, se recibe parametro llamado planID
    useEffect(() => {
        setCopyurl(apiConfig.mercadoPago.returnUrl.replace('cuenta-creada','planes/'));
        setValue('certifications', 0);
        if(params.get('planID')){
            handleFetch({
                url: 'bo/plans/'+params.get('planID'),
                method: 'GET', 
                headers: {Authorization: `Bearer ${authToken}`} 
            })
            .then(async (r) => {
                setIsEditing(true)
                let data = r[0];
                //consulta de endpoint de empresas B2B disponibles
                await handleFetch({
                    url:'bo/companiesb2b',
                    method: 'GET',
                    headers: {Authorization: `Bearer ${authToken}`}
                }).then((rc) => {
                    setCompanies(rc?.data)
                    let urlb2b = rc?.data.find(
                        (item) => parseInt(item.id) === parseInt(data.companyId)
                    );
                    setCopyurl(urlb2b.urlb2b+'planes/'+createSplit(data.title));
                },
                error => {
                    console.log(error.errors);
                })
                setValue('title', data.title)
                setValue('duration', data.duration)
                setValue('durationPeriod', data.durationPeriod)
                setValue('durationText', data.durationText)
                setValue('description', data.description)
                setValue('price', data.price)
                setValue('compared_price', data.compared_price)
                if(data.certifications){
                    setValue('certifications', data.certifications)
                }
                setValue('highlighted', data.highlighted)
                setValue('enabled', data.enabled)
                setValue('call_to_action_text', data.call_to_action_text)
                setValue('call_to_action_style', data.call_to_action_style)
                setBenefits(data.benefits)
                setValue('typeplan', data.typeplan)                
                setValue('companyId', data.companyId)
                setValue('urlenabled', data.urlenabled)
                setValue('canCertificate', data.canCertificate)
                setValue("dateActivate", moment.utc(data?.dateActivate).format('YYYY-MM-DD'));
                setValue("dateDesactivate", moment.utc(data?.dateDesactivate).format('YYYY-MM-DD'));
            })
            .catch(error => {
                setIsEditing(false)
            })
        }
        //consulta de endpoint de empresas B2B disponibles
        const response = handleFetch({
            url:'bo/companiesb2b',
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setCompanies(r?.data)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {

                    }

                })
    
    }, [])
    // Función para cerrar toast
    const afterCloseErrorToast = () => {
        setToastProperties({show:false});
    }
    // Función submit para editar plan
    const onSubmit = data => {
        if(data.compared_price==='') delete data.compared_price; 
        const method = isEditing? 'PATCH' : 'POST';
        if (!isEditing) {
            data.dateActivate = moment().format('YYYY-MM-DD');
            data.dateDesactivate = moment().format('YYYY-MM-DD');
        }
        const urlPlans ='bo/plans/' + (isEditing ? params.get('planID'): '');
        const urlPlanBenefits ='bo/assoc_plan_benefits/' + (isEditing ? params.get('planID'): '');

           
            handleFetch({
                url:urlPlans,
                method: method, 
                data: data,
                headers: {Authorization: `Bearer ${authToken}`} 
            })
            .then(r=>{
                if(benefitsGetter) {
                    return handleFetch({
                        url:urlPlanBenefits,
                        method: method, 
                        data: {planId:r.id, benefits: benefitsGetter.getBenefits()},
                        headers: {Authorization: `Bearer ${authToken}`} 
                        });
                }
            })
            .then(()=>{
               navigate('/admin/planes',{state: {
                                                    toastProperties:{
                                                        show:true,
                                                        type:'success',
                                                        title: 'Operación exitosa',
                                                        message: 'El plan se guardó correctamente.',
                                                        position: {horizontal:'left'},
                                                    }
                                                }
                                        });
            })
            .catch((error) => {
                console.log(error);
                if(error.code===409){
                   setToastProperties({
                    show:true,
                    type:'error',
                    message: error.message
                   })
                }else{
                    setToastProperties({
                        show:true,
                        type:'error',
                        message: 'Error al guardar el plan. Intenta nuevamente.'
                        })
                    }
            });
        
    }
    const copyToClipboard =  (e) => {
        e.preventDefault();
        var aux = document.createElement("input");
        aux.value = copyurl;
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        setToastProperties({
            show: true,
            type: 'success',
            title: 'Operación exitosa',
            message: 'Se ha copiado exitosamente la url en el portapapeles.',
            position: { horizontal: 'left' },
        });
      };
    const handlePlanChange = (event) => {
        let band = 0;
        if(event.target.name==="price"){
            if (getValues('typeplan') === '2' || getValues('typeplan') === '3' || getValues('typeplan') === '4'){
                band = true;
            }
        }else if(event.target.name==="typeplan"){
            if(event.target.value === '2' || event.target.value === '3' || event.target.value === '4'){
                if(getValues('price') !== 0){
                    band = true;
                }
            }
        }
        if(band==true){
            setValue('price',0);
            setToastProperties({
                show:true,
                    type:'error',
                    title: 'Error',
                    message: 'El precio debe ser 0 para planes sin pago.',
                    position: {horizontal:'left'},
                });
            band=false;
        }
    };
    return (
        <>
            <Link to='/admin/planes' className='px-4 px-sm-5 text_primary fw-bold'>Volver a planes</Link>

            <div className='text-center'><h1>Crear Plan </h1> </div>
            <div className='d-flex justify-content-center'>
                <div className='PlanFormContainer row m-2 p-3 col col-md-10'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Título</label>
                            <input 
                                placeholder={CONSTANTS.LABEL_PLAN_TITLE_PLACEHOLDER}
                                {...register("title",{required:CONSTANTS.MESSAGE_PLAN_TITLE_REQUIRED})} 
                                className={`form-control PlanFormContainer__input ${errors.title?'PlanFormContainer__input--error':''}`}>
                            </input>
                            <ErrorMessage message={errors.title?errors.title.message:''}/> 
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-6">
                                    <label className="form-label PlanFormContainer__label">Duración</label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_PLAN_DURATION_PLACEHOLDER}
                                        type="number"
                                        {...register("duration",{required:CONSTANTS.MESSAGE_PLAN_DURATION_REQUIRED})} 
                                        className={`form-control PlanFormContainer__input ${errors.duration?'PlanFormContainer__input--error':''}`}
                                    >
                                    </input>
                                    <ErrorMessage message={errors.duration?errors.duration.message:''}/> 
                                </div>
                                <div className="col-6">
                                    <label className="form-label PlanFormContainer__label">Tiempo</label>
                                    <select {...register("durationPeriod")} className="form-select PlanFormContainer__input">
                                        <option value='1'>Días</option>
                                        <option value='2'>Semanas</option>
                                        <option value='3'>Meses</option>
                                        <option value='4'>Años</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Descripción en negrilla</label>
                            <input 
                                placeholder={CONSTANTS.LABEL_PLAN_DURATION_TEXT_PLACEHOLDER}
                                {...register("durationText")} 
                                className={`form-control PlanFormContainer__input ${errors.durationText?'PlanFormContainer__input--error':''}`}>
                            </input>
                            <ErrorMessage message={errors.durationText?errors.durationText.message:''}/> 
                        </div>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Descripción</label>
                            <textarea 
                                placeholder={CONSTANTS.LABEL_PLAN_DESCRIPTION_PLACEHOLDER}
                                {...register("description",
                                {required:CONSTANTS.MESSAGE_PLAN_DESCRIPTION_REQUIRED})}
                                className={`form-control PlanFormContainer__input ${errors.description?'PlanFormContainer__input--error':''}`}>
                            </textarea>
                            <ErrorMessage message={errors.description?errors.description.message:''}/> 
                        </div>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Costo Actual</label>
                            <div className="input-group">
                                <input 
                                    placeholder={CONSTANTS.LABEL_PLAN_PRICE_PLACEHOLDER}
                                    type="number"
                                    {...register("price",{
                                        required:CONSTANTS.MESSAGE_PLAN_PRICE_REQUIRED,
                                        pattern: {
                                            value: CONSTANTS.PATTERN_FLOAT,
                                            message: CONSTANTS.MESSAGE_PLAN_PRICE_INVALID
                                        }})}  
                                    onChange={handlePlanChange} 
                                    className={`form-control PlanFormContainer__input ${errors.price?'PlanFormContainer__input--error':''}`}
                                >
                                </input>
                                <span className="input-group-text">COP</span>
                            </div>
                            <ErrorMessage message={errors.price?errors.price.message:''}/> 
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-6">
                                    <label className="form-label PlanFormContainer__label">Seleccionar Empresa B2B</label>
                                    <select {...register("companyId")} defaultValue='1' className="form-select PlanFormContainer__input">
                                        <option value="" selected defaultValue=''>Selecciona un B2B</option>
                                    {
                                        companies.length !== 0 &&
                                            companies.map((data, index)=>{
                                                return(
                                                    <option value={data?.id} key={data?.id}>{data?.name}</option>
                                                );
                                            })
                                    }
                                    </select> 
                                </div>
                                <div className="col-6">
                                    <label className="form-label PlanFormContainer__label">Tipo de plan</label>
                                    <select {...register("typeplan")} className="form-select PlanFormContainer__input" onChange={handlePlanChange}>
                                        <option value="" selected defaultValue=''>Selecciona un plan</option>
                                        <option value="1">Planes pagos</option>
                                        <option value="2">Planes cortesía</option>
                                        <option value="3">Planes gratuitos</option>
                                        <option value="4">Planes B2B</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4 mb-3'>
                            <div className='row'>
                                <div className="col-6">
                                    <label className="form-label PlanFormContainer__label">Fecha activación del plan</label>
                                    <input
                                        name='dateActivate'
                                        type='date'
                                        placeholder={CONSTANTS.LABEL_ACTIVATEPLAN_PLACEHOLDER}
                                        {...register("dateActivate", { required: CONSTANTS.MESSAGE_ACTIVATEPLAN_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.dateActivate ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.dateActivate ? errors.dateActivate.message : ''} />
                                </div>
                                <div className="col-6">
                                    <label className="form-label PlanFormContainer__label">Fecha desactivación del plan</label>
                                    <input
                                        name='dateDesactivate'
                                        type='date'
                                        placeholder={CONSTANTS.LABEL_DESACTIVATEPLAN_PLACEHOLDER}
                                        {...register("dateDesactivate", { required: CONSTANTS.MESSAGE_DESACTIVATEPLAN_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.dateDesactivate ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.dateDesactivate ? errors.dateDesactivate.message : ''} />
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 mb-3'></div>
                        <PlanBenefitsList setBenefitsGetter={setBenefitsGetter} benefits={benefits} />
                        <div className='mb-3'>
                            <div className="form-check form-switch">
                                <label className="form-check-label">Recomendado</label>
                                <input 
                                    {...register("highlighted")}  
                                    className="form-check-input PlanFormContainer__check-input" 
                                    type="checkbox"
                                >
                                </input>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className="form-check-label PlanFormContainer__label">Certificable</div>
                            <div className="form-check form-switch">
                                <label className="form-check-label">{ checkboxcertifenabled === true ? "Activo" : "Inactivo"}</label>
                                <input 
                                    {...register("canCertificate")} 
                                    type="checkbox" 
                                    defaultChecked
                                    className="form-check-input PlanFormContainer__check-input"
                                >
                                </input>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className="form-check-label PlanFormContainer__label">Estado</div>
                            <div className="form-check form-switch">
                                <label className="form-check-label">{ checkboxenabled === true ? "Activo" : "Inactivo"}</label>
                                <input 
                                    {...register("enabled")} 
                                    type="checkbox" 
                                    defaultChecked
                                    className="form-check-input PlanFormContainer__check-input"
                                >
                                </input>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className="form-check-label PlanFormContainer__label">Activar Ruta del Plan</div>
                            <div className="form-check form-switch">
                                <label className="form-check-label">{ checkboxurlenabled === true ? "Activo" : "Inactivo"}</label>
                                <input 
                                    {...register("urlenabled")} 
                                    type="checkbox" 
                                    defaultChecked
                                    className="form-check-input PlanFormContainer__check-input"
                                >
                                </input>
                            </div>
                        </div>
                        { params.get('planID') &&
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Ruta a utilizar</label>
                            <div className="input-group">
                                <input 
                                    value={copyurl}
                                    disabled
                                    type="text"
                                    className={`form-control PlanFormContainer__input`}
                                >
                                </input>
                                <span className="input-group-text">
                                <Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => copyToClipboard(event)}><i className='fa-solid fa-copy UserAdmin__color-red'></i></Link>
                                   </span>
                            </div> 
                        </div>}
                        <div className='fw-bold'>                    
                            <label className="form-label PlanFormContainer__label">
                                <i>Call to Action</i>
                            </label>
                        </div>
                        <div className='PlanFormContainer__group'>
                            <div className="row mb-3">
                                <div className="col-12 col-sm-7">
                                    <label className="form-label PlanFormContainer__label">Etiqueta</label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_PLAN_ACTION_LABEL_PLACEHOLDER}
                                        {...register("call_to_action_text",{required:CONSTANTS.MESSAGE_PLAN_ACTION_LABEL_REQUIRED})}
                                        className={`form-control PlanFormContainer__input ${errors.call_to_action_text?'PlanFormContainer__input--error':''}`}
                                    >
                                    </input>
                                    <ErrorMessage message={errors.call_to_action_text?errors.call_to_action_text.message:''}/> 
                                </div>
                                <div className="col-12 col-sm-5">
                                    <label className="form-label PlanFormContainer__label">Estilo</label>
                                    <select {...register("call_to_action_style")} defaultValue='0' className="form-select PlanFormContainer__input">
                                        <option value='0'>Normal</option>
                                        <option value='1'>Resaltado</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <SubmitFormButton
                            main_title="Guardar" 
                            wait_title="Guardando..." 
                            loading={fetchData.loading}>
                        </SubmitFormButton>
                    </form>
                    <ErrorMessage message={fetchData.error?((!!fetchData.error.code)?CONSTANTS.MESSAGE_PLAN_ERROR:CONSTANTS.MESSAGE_GENERIC_ERROR):''}/> 
                </div>
            </div>
            <Toast title="Error" {...toastProperties} closeCallback={afterCloseErrorToast} position={{vertical:'bottom', horizontal: 'left'}} ></Toast>
        </>
    );
}