import React from 'react'
import { Link } from 'react-router-dom'
import { AuthFormContainerPage } from '../controls/UIControls'

const landingImages = require.context('../../assets/images', true)

export const PaymentErrorPage = () => {

    const properties = {
        title: "Ha ocurrido un error realizando el pago",
        header: (<div>
            <img
                alt='Ean logo'
                src={landingImages('./error.png')}
                style={{width: "100px", height: "auto"}}
                className="mx-auto d-block auth__img-check mb-3"
            />
        </div>),
        content: (<Link to="/pasarela" replace >
                <button className="auth__btn-form">Volver a la pasarela de pago</button>
            </Link>
        )
    }
    return <AuthFormContainerPage {...properties}></AuthFormContainerPage>
}