import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LandingPage } from '../components/landing/LandingPage'
import { PlanPage } from '../components/plan/PlanPage'
import { VerifyEmailPage } from '../components/register/VerifyEmailPage'
import { AccountCreatedPage } from '../components/register/AccountCreatedPage'
import { RegisteredPurchasePage } from '../components/register/RegisteredPurchasePage'
import { ValidatingPurchasePage } from '../components/register/ValidatingPurchasePage'
import { PaymentErrorPage } from '../components/register/PaymentErrorPage'
import { ProfilePage } from '../components/profile/ProfilePage'
import { UserContext } from '../context/UserContext'
import { LoginPage } from '../components/login/LoginPage'
import { PasswordRecoveryPage } from '../components/password-recovery/PasswordRecoveryPage'
import { PasswordMessageSentPage } from '../components/password-recovery/PasswordMessageSentPage'
import { SuccessfulKeyChangePage } from '../components/change-password/SuccessfulKeyChangePage'
import { ResetPasswordPage } from '../components/reset-password/ResetPasswordPage'
import { CatalogPage } from '../components/catalog/CatalogPage'
import { CourseDetailPage } from '../components/course-detail/CourseDetailPage'
import { ContactUsPage } from '../components/contact-us/ContactUsPage'
import { PlanAdminPage } from '../components/admin/plans/PlanAdminPage'
import { UserAdminPage } from '../components/admin/users/UserAdminPage'
import { ReportsAdminPage } from '../components/admin/reports/ReportsAdminPage'
import { ReportsIndividualCoursesPage } from '../components/admin/reports/ReportsIndividualCoursesAdminPage'
import { ReportsDetailPage } from '../components/admin/reports/ReportsDetailPage'
import { ReportsCourseAdminPage } from '../components/admin/reports/ReportsCourseAdminPage'
import { NotFoundPage } from '../components/not-found/NotFoundPage'
import { PlandCoursePage } from '../components/admin/plans/PlandCoursePage'
import { PlanCreateEditPage } from '../components/admin/plans/PlanCreateEditPage'
import { UserCreateEditPage } from '../components/admin/users/UserCreateEditPage'
import { IdVerificationPage } from '../components/id-verification/IdVerification'
import { IdQRVerificationPage, DirectRegisterQR } from '../components/qrvalidation/QRVerification'
import { IdCertificationVerificationPage } from '../components/certificationvalidation/CertificationValidation'
import { UpdateData } from '../components/id-verification/UpdateData'
import { B2BUserPage } from '../components/admin/b2b/B2BUserPage'
import { B2BCoursePage } from '../components/admin/b2b/B2BCoursePage'
import { PaymentTypePage } from '../components/register/PaymentTypePage'
import { SelectRegister } from '../components/register/SelectRegister'
import { DirectRegister } from '../components/register/DirectRegisterPage'
import { B2BAdminPage } from '../components/admin/b2b/B2BAdminPage'
import { B2BCreateEditPage } from '../components/admin/b2b/B2BCreateEditPage'
import { CertificationPage } from '../components/admin/certifications/CertificationPage'
import { CertificationGenePage } from '../components/admin/certificationsgene/CertificationGenePage'
import { SurveyPage } from '../components/admin/surveys/SurveyPage'
import { LandingPageAdmin } from '../components/admin/landing/LandingPage'
import { ProfilePageAdmin } from '../components/admin/profile/ProfilePage'
import { LandingHomePage } from '../components/landingb2b/LandingHomePage'
import { LandingFormPage } from '../components/landingb2b/LandingFormPage'
import { LandingThanksPage } from '../components/landingb2b/LandingThanksPage'
import { DashboardAdminPage } from '../components/admin/dashboard/DashboardAdminPage'
import { DashboardReportsAdminPage } from '../components/admin/dashboard/DashboardReportsAdminPage'
import { LastSessionAdminPage } from '../components/admin/lastsession/LastSessionAdminPage'
import { DiscountPage } from '../components/admin/discounts/DiscountPage'
import { ReportsCouponPage } from '../components/admin/reports/ReportsCouponPage'
import { CertificatesPageAdmin } from '../components/admin/certificates/CertificatesPage'
import { ReportsAdminReviewsPage } from '../components/admin/reports/ReportsAdminReviewsPage'
import { ReportsIndividualCoursesReviewsPage } from '../components/admin/reports/ReportsIndividualCoursesReviewsPage'
import { B2BPlandCoursePage } from '../components/admin/b2b/B2BPlandCoursePage'
import { QRCodesPage } from '../components/admin/QRCodes/QRCodesPage'
import { FaqPlacetopayPage } from '../components/faqplacetopay/faqplacetopay'
import { ViewCertification } from '../components/certificationvalidation/ViewCertification'
// Archivo con rutas activas en sitio eanx
export const AppRouter = () => {
  const [user, setUser] = useState('');

  return (
    <UserContext.Provider value={{
      user,
      setUser,
    }}>

      <BrowserRouter>
        <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/*' element={<NotFoundPage />} />
            <Route path='/planes' element={<PlanPage />} />
            <Route path='/perfil' element={<ProfilePage />}/>
            <Route path='/registro' element={<SelectRegister />} />
            <Route path='/cursos' element={<CatalogPage/>} />
            <Route path='/curso/:courseID' element={<CourseDetailPage />} />
            <Route path='/contactanos' element={<ContactUsPage />} />
            <Route path='/verificar-email' element={<VerifyEmailPage />} />
            <Route path='/tipo-de-pago' element={<PaymentTypePage />} />
            <Route path='/cuenta-creada' element={<AccountCreatedPage />} />
            <Route path='/pago-registrado' element={<RegisteredPurchasePage />} />
            <Route path='/validando-compra' element={<ValidatingPurchasePage />} />
            <Route path='/error-pago' element={<PaymentErrorPage />} />
            <Route path='/ingreso' element={<LoginPage isAdmin={false} />} />
            <Route path='/admin/ingreso' element={<LoginPage isAdmin={true} />} />
            <Route path='/admin' element={<LoginPage isAdmin={true} />} />
            <Route path='/admin/cursosenplanes' element={<PlandCoursePage />} />
            <Route path='/admin/planes' element={<PlanAdminPage />} />
            <Route path='/admin/planes/crear' element={<PlanCreateEditPage />} />
            <Route path='/admin/facturacion' element={<ReportsAdminPage />} />
            <Route path='/admin/facturacioncursos' element={<ReportsIndividualCoursesPage />} />
            <Route path='/admin/facturacion/detalle' element={<ReportsDetailPage />} />
            <Route path='/admin/facturacionreviews' element={<ReportsAdminReviewsPage />} />
            <Route path='/admin/facturacioncursosreviews' element={<ReportsIndividualCoursesReviewsPage />} />
            <Route path='/admin/reportescursos' element={<ReportsCourseAdminPage />} />
            <Route path='/admin/usuarios' element={<UserAdminPage />} />
            <Route path='/admin/usuarios/editar/:id' element={<UserCreateEditPage />} />
            <Route path='/admin/certicertika' element={<CertificationPage />} />
            <Route path='/admin/certieanx' element={<CertificatesPageAdmin />} />
            <Route path='/admin/certigenerados' element={<CertificationGenePage />} />
            <Route path='/admin/encuestas' element={<SurveyPage />} />
            <Route path='/admin/descuentos' element={<DiscountPage />} />
            <Route path='/admin/reportedescuentos' element={<ReportsCouponPage />} />
            <Route path='/admin/landing' element={<LandingPageAdmin />} />
            <Route path='/admin/profile' element={<ProfilePageAdmin />} />
            <Route path='/admin/b2b' element={<B2BAdminPage />} />
            <Route path='/admin/b2b/crear' element={<B2BCreateEditPage />} />
            <Route path='/admin/b2b/usuarios' element={<B2BUserPage />} />
            <Route path='/admin/b2b/segmentacion' element={<B2BCoursePage />} />
            <Route path='/admin/b2b/courseondemand' element={<B2BPlandCoursePage />} />
            <Route path='/admin/b2b/editar/:id' element={<B2BCreateEditPage />} />
            <Route path='/admin/dashboard' element={<DashboardAdminPage />} />
            <Route path='/admin/dashboardreport' element={<DashboardReportsAdminPage />} />
            <Route path='/admin/ultimasesion' element={<LastSessionAdminPage />} />
            <Route path='/recuperar-contrasena' element={<PasswordRecoveryPage />} />
            <Route path='/restablecer-contrasena' element={<ResetPasswordPage />} />
            <Route path='/mensaje-contrasena-enviado' element={<PasswordMessageSentPage />} />  
            <Route path='/cambio-contrasena-exitoso' element={<SuccessfulKeyChangePage />} /> 
            <Route path='/actualizar-datos' element={<IdVerificationPage />} />
            <Route path='/verificar-datos' element={<IdVerificationPage />} />
            <Route path='/actualizar' element={<UpdateData />} />
            <Route path='/planes/:slugplan' element={<DirectRegister />} />
            <Route path='/empresas' element={<LandingHomePage />} />
            <Route path='/empresas/contacto' element={<LandingFormPage />} />
            <Route path='/empresas/gracias' element={<LandingThanksPage />} />
            <Route path='/faqplacetopay' element={<FaqPlacetopayPage />} />
            <Route path='/certificate' element={<IdCertificationVerificationPage />} />
            <Route path='/certificate/:code' element={<ViewCertification />} />
            <Route path='/qr' element={<IdQRVerificationPage />} />
            <Route path='/qr/:code' element={<DirectRegisterQR />} />
            <Route path='/admin/registrosqr' element={<QRCodesPage />} />
        </Routes>
      </BrowserRouter>  
    </UserContext.Provider>
  )
}
