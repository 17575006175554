import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { useForm as hookUseForm } from 'react-hook-form';
import { useLocation, Link } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast } from '../../controls/UIControls';
import * as CONSTANTS from '../../../assets/constants';
import Modal from '@mui/material/Modal';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección edición de landing
 * @interface CertificatesSection
 */
export const CertificatesSection = () => {
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const [certificate, setCertificate] = useState([]);
    const { authToken } = useAuthToken(true);
    const [fetchData, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });    
    const [showModalCreateImg, setShowModalCreateImg] = useState(false);
    const [editImgCarousel, setEditImgCarousel] = useState(false)    
    const [ideditImg, setIdeditImg] = useState(0)
    const [selectActive, setSelectActive] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const types = ['Cápsula', 'Curso', 'Plan de estudio', 'Insignia'];
    const colors = ['Negro', 'Azúl', 'Verde'];
    const location = useLocation();
    const { pathname } = location;
    const handleSelectorChange = () => {
        setSelectActive(!selectActive);
    };
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const refreshImgs = () => {
        //consulta de endpoint de certificados disponibles
        const responsec = handleFetch({
            url: `bo/certificatetemplates`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsec
            .then((r) => {
                setCertificate(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    // Consulta de endpoint listado de encuestas
    useEffect(() => {
        refreshImgs()
    }, [])   
    // Header para certificados
    const headers = [
        {
            "name": "IMAGEN CERTIFICADO",
            "width": "30%"
        },
        {
            "name": "ID TEMPLATE",
            "width": "20%"
        },
        {
            "name": "TIPO",
            "width": "13%"
        },
        {
            "name": "COLOR",
            "width": "13%"
        },{
            "name": "ACTIVO",
            "width": "13%"
        },
        {
            "name": "ACCIONES",
            "width": "11%"
        }
    ];
    // Función mostrar modal en caso de registro certificado
    const handleOpenModalCreateImg = async () => {
        setValue("certificateImg",'')
        setShowModalCreateImg(true);
    }
    // Función mostrar modal en caso de editar certificado
    const handleOpenModaleditImg = (id, event) => {
        event.preventDefault();
        const cert = certificate.find(
            (item) => parseInt(item.id) === id,
        );
        setValue("certificateImg", cert.certificateImg);
        setValue("type", cert.type);
        setValue("color", cert.color);
        setValue("active", cert.active);
        setEditImgCarousel(true);
        setIdeditImg(id);
        setShowModalCreateImg(true);
    }
    
    const handleDeleteImg = (id, event) => {
        event.preventDefault();
        handleFetch({
            url:`bo/certificatetemplates/${id}`,
            method: 'DELETE',
            headers: { Authorization: `Bearer ${authToken}` }
        }).then(() => {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha eliminado el Certificado Exitosamente.',
                position: { horizontal: 'left' },
            });
            refreshImgs();
        })
        .catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error al eliminar el Certificado. Intenta nuevamente.',
                position: { horizontal: 'left' },
            });
        });
    }
    // Handle para asignar plan al B2B
    const handleCreateImg = (formValues) => {
        handleFetch({
                url: 'bo/certificatetemplates' + (editImgCarousel ? `/${ideditImg}` : ''),
                method: editImgCarousel ? 'PATCH' : 'POST', 
                headers: { Authorization: `Bearer ${authToken}` },
                data: getRequestDataImg(formValues),
            })
            .then(r=>{
                setToastProperties({
                    show:true,
                    type:'success',
                    title: 'Operación exitosa',
                    message: 'El Certificado se guardó correctamente.',
                    position: {horizontal:'left'},
                })
                refreshImgs();
                setShowModalCreateImg(false);
            })
            .catch((error) => {
                console.log(error);
                if(error.code===409){
                   setToastProperties({
                    show:true,
                    type:'error',
                    message: error.message,
                    position: {horizontal:'left'},
                   })
                }else{
                    setToastProperties({
                        show:true,
                        type:'error',
                        message: 'Error al guardar el certificado. Intenta nuevamente.',
                        position: {horizontal:'left'},
                        })
                    }
            });
    }
    const handleCloseModalCreateImg = () => {
        setShowModalCreateImg(false);
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestDataImg = (formValues) => {
        return {
            certificateImg: formValues.certificateImg,
            color: formValues.color,
            type: formValues.type,
            active: formValues.active
        }
    }
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalCreateImg}
                onClose={handleCloseModalCreateImg}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateImg)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Certificado</div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Imágen certificado</label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_URLIMG_CERTIFICATE_PLACEHOLDER}
                                        {...register("certificateImg")} 
                                        className={`form-control PlanFormContainer__input ${errors.certificateImg?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.certificateImg && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.certificateImg.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Tipo</label>
                                            <select {...register("type")} className="form-select PlanFormContainer__input">
                                                <option value='0'>Cápsula</option>
                                                <option value='1' selected>Curso</option>
                                                <option value='2'>Plan de estudio</option>
                                                <option value='3'>Insignia</option>
                                            </select>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Color</label>
                                            <select {...register("color")} className="form-select PlanFormContainer__input">
                                                <option value='0'>Negro</option>
                                                <option value='1'>Azúl</option>
                                                <option value='2'>Verde</option>
                                            </select>
                                        </div>
                                                                                    
                                        {errors.duration && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.duration.message}
                                                </span>
                                            )}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Estado del Certificado<span className='auth__required-input'>*</span></label>
                                    <div className="form-check form-switch">
                                        <input 
                                            {...register("active")} 
                                            type="checkbox" 
                                            defaultChecked
                                            className="form-check-input PlanFormContainer__check-input"
                                        >
                                        </input>
                                    </div>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>Agregar certificado</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateImg}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='text-center'><h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Certificados EanX</h1> </div>
            <div className='col-12 d-flex justify-content-center'>
                <div className='LandingHeader__text-and-border'>
                    <Link to={'../admin/certicertika'} className={`${pathname == '/admin/certicertika' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados Certika</Link>
                </div>
                <div className='LandingHeader__text-and-border'>
                    <Link to={'../admin/certieanx'} className={`${pathname == '/admin/certieanx' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados EanX</Link>
                </div>
                <div className='LandingHeader__text-no-border'>
                    <Link to={'../admin/certigenerados'} className={`${pathname == '/admin/certigenerados' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados Generados</Link>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='UserFormContainer row m-2 p-3 col-10 col-md-10'>
                    <div className='mb-2 mt-2'>
                        <div className='row'>
                            <div className="col-12 col-md-6">
                                <label className="form-label PlanFormContainer__label"></label>
                            </div>
                            <div className="col-12 col-md-6 text-end">
                            <Link to={{}} className="FilterAdmin__search-button" onClick={handleOpenModalCreateImg}>
                                <span>Agregar elemento</span>
                            </Link>
                            </div>
                        </div>
                    </div>
                    <div className='' style={{ width: "100%", marginTop: "17px", overflowX: "auto" }}>
                        <div className='mb-3'>
                            <table className='UserAdmin__table' >
                                <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                    <tr style={{ cursor: "pointer" }}>
                                        {headers.map((header, index) => {
                                            return (
                                                <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        certificate.length !== 0 ?
                                            certificate.map((data, index) => {
                                                return (
                                                    <tr className={`UserAdmin__table-row mt-2 mb-2 ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                        <td style={{ paddingLeft: "16px", textAlign: "center" }}><img src={data?.certificateImg} border="0" height="150" /></td>
                                                        <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data.template}</td>
                                                        <td style={{ paddingLeft: "16px", textAlign: "center" }}>{types[data.type]}</td>
                                                        <td style={{ paddingLeft: "16px", textAlign: "center" }}>{colors[data.color]}</td>
                                                        <td className="d-flex position-relative" style={{marginTop: "75px", paddingLeft: "16px"}}>
                                                            <div className="aligncenter form-check form-switch">
                                                                <input 
                                                                    name="active"
                                                                    value={data?.active}
                                                                    type="checkbox"
                                                                    className="form-check-input PlanFormContainer__check-input"
                                                                    defaultChecked={data?.active}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </td>
                                                        <td style={{ paddingLeft: "16px", textAlign: "center" }}><Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => handleOpenModaleditImg(data.id, event)}><i className='fa-solid fa-pen UserAdmin__color-red'></i></Link> <Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => handleDeleteImg(data.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td colSpan="7" style={{ padding: "16px", textAlign: "center" }}><strong>No existen registros</strong></td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}