import React from 'react';
import { useLocation } from 'react-router-dom';
import { AdminHeader } from '../plans/AdminHeader';
import { SimpleFooter } from '../../footer/Footer';
import { ReportsDetailSection } from './ReportsDetailSection';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección edición de usuarios
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const ReportsDetailPage = () => {
    const location = useLocation();
    const user = location.state?.users;
    return (
        <>
            <AdminHeader />
            <div style={{marginTop: "100px"}}></div>
            <ReportsDetailSection users={user}/>
            <SimpleFooter />
        </>
    )
}