import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { Link, useLocation } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { CSVLink } from 'react-csv';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado sección Reportes
 * @interface ReportsIndividualCoursesAdminSection
 */
export const ReportsIndividualCoursesAdminSection = () => {

    const [users, setUsers] = useState([]);
    const [plans, setPlans] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [dataFilter, setDataFilter] = useState({
        document: '',
        email: '',
        lastname: '',
        name: '',
        until: '',
        since: '',
        approvalCode: '',
        typeplan: '',
        paidPrice: '',
        statusPlan: '',
        studentType: '',
    })
    const { authToken } = useAuthToken(true);
    const location = useLocation();
    const { pathname } = location;
    // Consulta de endpoint reportes de pagos    
    useEffect(() => {
        let filterapprovalCode = dataFilter.approvalCode !== '' ? `&approvalCode=` + dataFilter.approvalCode : ''
        let filtertypeplan = dataFilter.typeplan !== '' ? `&typeplan=` + dataFilter.typeplan : ''
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filterprice = dataFilter.paidPrice !== '' ? dataFilter.paidPrice == 0 ? "&paidPrice[$eq]=0" : "&paidPrice[$gt]=0" : ''
        let aux = ''
        if(dataFilter.statusPlan ==0){
            aux = "&endsAt[$lt]="+new Date().toISOString()
        }else if(dataFilter.statusPlan ==1){
            aux = "&endsAt[$gte]="+new Date().toISOString()+"&startsAt[$lte]="+new Date().toISOString()
        }else{
            aux = "&startsAt[$gt]="+new Date().toISOString()
        }
        let filterstatus = dataFilter.statusPlan !== '' ? aux : ''
        let filterstudenttype = dataFilter.studentType !== '' ? "&type=" + dataFilter.studentType : ''
        const response = handleFetch({
            url: `bo/coursespurchased?${filterstudenttype}${filterstatus}${filterprice}${filterapprovalCode}${filtertypeplan}${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        // listado de planes para selector en filtro
        const responseplan = handleFetch({
            url: 'bo/plans?$limit=500&$sort[durationPeriod]=1&$sort[duration]=1',
            method: 'GET'
        });
        responseplan
            .then((plans) => {
                setPlans(plans)
            },
                error => {
                    console.log(error.errors);
                })
        // listado de reporte para crear csv
        const responsefull = handleFetch({
            url: `bo/coursespurchased?$limit=50000${filterstudenttype}${filterstatus}${filterprice}${filterapprovalCode}${filtertypeplan}${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsefull
            .then((r) => {
                let indiceGuion = ''
                let _payCodeUsers = [];
                r.data.forEach(user => {
                    if (user?.approvalCode != null) {
                        indiceGuion = user?.approvalCode.indexOf("-");
                        if (indiceGuion != -1) {
                            user["approvalCode"] = user["approvalCode"].substring(0, indiceGuion);
                        }
                    }
                    user["startsAt"] = new Date(user.startsAt).toISOString().split('T')[0];
                    user["endsAt"] = new Date(user.endsAt).toISOString().split('T')[0];
                    user["createdAt"] = new Date(user.createdAt).toISOString().split('T')[0];
                    user["updatedAt"] = new Date(user.updatedAt).toISOString().split('T')[0];
                    _payCodeUsers.push(user);
                });
                setUsersFull(_payCodeUsers);
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filterapprovalCode = dataFilter.approvalCode !== '' ? `&approvalCode=` + dataFilter.approvalCode : ''
        let filtertypeplan = dataFilter.typeplan !== '' ? `&typeplan=` + dataFilter.typeplan : ''
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filterprice = dataFilter.paidPrice !== '' ? dataFilter.paidPrice == 0 ? "&paidPrice[$eq]=0" : "&paidPrice[$gt]=0" : ''
        let aux = ''
        if(dataFilter.statusPlan ==0){
            aux = "&endsAt[$lt]="+new Date().toISOString()
        }else if(dataFilter.statusPlan ==1){
            aux = "&endsAt[$gte]="+new Date().toISOString()+"&startsAt[$lte]="+new Date().toISOString()
        }else{
            aux = "&startsAt[$gt]="+new Date().toISOString()
        }
        let filterstatus = dataFilter.statusPlan !== '' ? aux : ''
        let filterstudenttype = dataFilter.studentType !== '' ? "&type=" + dataFilter.studentType : ''
        const response = handleFetch({
            url: `bo/coursespurchased?${filterstudenttype}${filterstatus}${filterprice}${filterapprovalCode}${filtertypeplan}${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        // listado de reporte para crear csv
        const responsefull = handleFetch({
            url: `bo/coursespurchased?$limit=50000${filterstudenttype}${filterstatus}${filterprice}${filterapprovalCode}${filtertypeplan}${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsefull
            .then((r) => {
                let indiceGuion = ''
                let _payCodeUsers = [];
                r.data.forEach(user => {
                    if (user?.approvalCode != null) {
                        indiceGuion = user?.approvalCode.indexOf("-");
                        if (indiceGuion != -1) {
                            user["approvalCode"] = user["approvalCode"].substring(0, indiceGuion);
                        }
                    }
                    user["startsAt"] = new Date(user["startsAt"]).toISOString().split('T')[0];
                    user["endsAt"] = new Date(user["endsAt"]).toISOString().split('T')[0];
                    user["createdAt"] = new Date(user["createdAt"]).toISOString().split('T')[0];
                    user["updatedAt"] = new Date(user["updatedAt"]).toISOString().split('T')[0];
                    _payCodeUsers.push(user);
                });
                setUsersFull(_payCodeUsers);
            },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Facturación de cursos</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                        <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/facturacioncursos'} className={`${pathname == '/admin/facturacioncursos' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/facturacion'} className={`${pathname == '/admin/facturacion' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Planes</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/facturacioncursosreviews'} className={`${pathname == '/admin/facturacioncursosreviews' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos en revisión</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/facturacionreviews'} className={`${pathname == '/admin/facturacionreviews' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Planes en revisión</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listreports.csv"}
                            onSubmit={handleSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='number' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Correo</span>
                                <div>
                                    <input type='text' name='email' value={dataFilter?.email || ''} onChange={handleChangeFilter} placeholder="Correo electrónico" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Código de aprobación</span>
                                <div>
                                    <input type='text' name='approvalCode' value={dataFilter?.approvalCode || ''} onChange={handleChangeFilter} placeholder="Código de aprobación" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de usuario</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='studentType'
                                        value={dataFilter?.studentType || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="1">Público General</option>
                                        <option value="2">Estudiantes Eanistas</option>
                                        <option value="3">Docente de Planta</option>
                                        <option value="4">Docente de Cátedra</option>
                                        <option value="5">Colaborador</option>
                                        <option value="6">Egresado</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Inicio</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={dataFilter?.until || newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Fin</span>
                                <div>
                                    <input type="date" name="until" value={dataFilter?.until || ''} onChange={handleChangeFilter} min={dataFilter?.since} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable users={users} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar reportes
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ users, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de reportes
    const headers = [
        {
            "name": "NOMBRE COMPLETO",
            "sort_name": "name",
            "width": "20%"
        },
        {
            "name": "CORREO ELECTRÓNICO",
            "sort_name": "email",
            "width": "20%"
        },
        {
            "name": "CURSO ADQUIRIDO",
            "sort_name": "course",
            "width": "30%"
        },
        {
            "name": "MONTO",
            "sort_name": "amount",
            "width": "8%"
        },
        {
            "name": "FECHA COMPRA",
            "sort_name": "datepurchase",
            "width": "12%"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))

    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(total)
    }, [users, total])

    const resetState = () => {
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(users.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filterapprovalCode = dataFilter.approvalCode !== '' ? `&approvalCode=` + dataFilter.approvalCode : ''
        let filtertypeplan = dataFilter.typeplan !== '' ? `&typeplan=` + dataFilter.typeplan : ''
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filterprice = dataFilter.paidPrice !== '' ? dataFilter.paidPrice == 0 ? "&paidPrice[$eq]=0" : "&paidPrice[$gt]=0" : ''
        let aux = ''
        if(dataFilter.statusPlan ==0){
            aux = "&endsAt[$lt]="+new Date().toISOString()
        }else if(dataFilter.statusPlan ==1){
            aux = "&endsAt[$gte]="+new Date().toISOString()+"&startsAt[$lte]="+new Date().toISOString()
        }else{
            aux = "&startsAt[$gt]="+new Date().toISOString()
        }
        let filterstatus = dataFilter.statusPlan !== '' ? aux : ''
        let filterstudenttype = dataFilter.studentType !== '' ? "&type=" + dataFilter.studentType : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/reportsusersplans?${filterstudenttype}${filterstatus}${filterprice}${filterapprovalCode}${filtertypeplan}${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}&$skip=${result}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "5%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        usersSorted.length != 0 ?
                            usersSorted.map((user, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user?.['webuser.name']} {user?.['webuser.lastname']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}><div className="UserAdmin__table-td">{user?.['webuser.email']}</div></td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user['course.title']}</td>
                                        <td className="overflow" style={{ textAlign: "center" }}>{user?.paidPrice}</td>
                                        <td className="overflow" style={{ textAlign: "center" }}>{new Date(user.startsAt).toISOString().split('T')[0]}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={`/admin/facturacion/detalle`} state={{users: user}}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="7" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}