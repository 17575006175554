import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useFetch } from '../../../hooks/useFetch';
import { useAuthToken } from '../../../hooks/useAuthToken';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Header utilizado en la sección backoffice
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const AdminHeader = ({searchAction}) => {
  
  const { buttonProps, isOpen } = useDropdownMenu(2);
  const navigate = useNavigate();
  const [data, handleFetch] = useFetch();
  const {authRedirect, isLogged, removeAuthToken, removeSessionUser, getSessionUser}= useAuthToken(true);
  const logged = isLogged();
  const location = useLocation();
  const { pathname } = location;
  // Al cerrar sesión se envía a la vista de ingreso
  const logout = ()=>{
    removeAuthToken();
    removeSessionUser();
    navigate("/admin/ingreso");

  }

  const {user, setUser} = useContext(UserContext);
  useEffect(()=> {
    authRedirect();
    if(logged){
      const sessionUser = getSessionUser();
      setUser(sessionUser);
    }
  },[]);
  

  return (
    <div className='px-4 px-sm-5 navbar-nav-scroll navbar-nav LandingHeader__navbar'>
      <div className='row justify-content-between justify-md-conent-center align-items-center text-center'>
        <div className='col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1  d-flex justify-content-start'>
          <Link to="/">
              <img alt='Ean logo' className='img-fluid' src={landingImages('./ean-logo-sin 1.png')}  style={{maxWidth:"80px", cursor: "pointer"}}/>
          </Link>
        </div>
          <div className='col-4 col-sm-4 col-md-6 col-lg-6 col-xl-7 d-none d-lg-block d-flex justify-content-start'>
              <div className='' style={{display: "ruby"}}>
                  <div className='LandingHeader__text-and-border'>
                      <Link to={'../admin/b2b'} className={`${pathname == '/admin/b2b' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>B2B</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                      <Link to={'../admin/certicertika'} className={`${pathname == '/admin/certicertika' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                    <Link to={'../admin/dashboard'} className={`${pathname == '/admin/dashboard' ? 'LandingHeader__text-black-color-active overflow' : 'LandingHeader__text-black-color overflow'}`}>Dashboard</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                      <Link to={'../admin/encuestas'} className={`${pathname == '/admin/encuestas' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Encuestas</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                      <Link to={'../admin/descuentos'} className={`${pathname == '/admin/descuentos' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Descuentos</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                      <Link to={'../admin/facturacion'} className={`${pathname == '/admin/facturacion'|| pathname == '/admin/facturacioncursos' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Facturación</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                      <Link to={'../admin/landing'} className={`${pathname == '/admin/landing' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Landing</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                      <Link to={'../admin/planes'} className={`${pathname == '/admin/planes' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Planes</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                    <Link to={'../admin/reportescursos'} className={`${pathname == '/admin/reportescursos' ? 'LandingHeader__text-black-color-active overflow' : 'LandingHeader__text-black-color overflow'}`}>Reportes cursos</Link>
                  </div>
                  <div className='LandingHeader__text-and-border'>
                    <Link to={'../admin/ultimasesion'} className={`${pathname == '/admin/ultimasesion' ? 'LandingHeader__text-black-color-active overflow' : 'LandingHeader__text-black-color overflow'}`}>Última sesión</Link>
                  </div>
                  <div className='LandingHeader__text-no-border' >
                      <Link to={'../admin/usuarios'} className={`${pathname == '/admin/usuarios' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Usuarios</Link>
                  </div>
              </div>
          </div>
        <div className='d-flex col-4 col-sm-6 col-md-5 col-lg-4 align-items-center justify-content-end' style={{paddingRight: "6px"}}>
            <div className='col-11 col-lg-12 d-flex LandingHeader__user-name-and-image justify-content-end'>
                <button className='col-12 LandingHeader__user-name-dropdown-button justify-content-end' {...buttonProps}>
                <span className='LandingHeader__user-name-text d-none d-sm-block'>{user.name + " " + user.lastname}</span>
                <span className='LandingHeader__user-name-text d-sm-none'>{user.name}</span>
                <div className='LandingHeader__user-icon-container'>
                    <i className='fa fa-user' style={{color: "white"}}></i>
                </div>
                </button>
                <div className={isOpen ? 'visible LandingHeader__profile-menu' : ''} style={isOpen? {}:{width: "0px"}} role='menu'>
                <div>
                  <div className='' style={{justifyContent: 'center'}}>
                      <div>
                        <i className="fa-solid fa-building"></i> <Link to={'../admin/b2b'} className={`LandingHeader__text-black-color`}>B2B</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-building"></i> <Link to={'../admin/certicertika'} className={`LandingHeader__text-black-color`}>Certificados</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-file-invoice"></i> <Link to={'../admin/dashboard'} className={`LandingHeader__profile-menu-text-color`}>Dashboard</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-building"></i> <Link to={'../admin/encuestas'} className={`LandingHeader__text-black-color`}>Encuestas</Link>
                      </div>                      
                      <div>
                        <i className="fa-solid fa-file-invoice"></i> <Link to={'../admin/facturacion'} className={`LandingHeader__text-black-color`}>Facturación</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-building"></i> <Link to={'../admin/descuentos'} className={`LandingHeader__text-black-color`}>Descuentos</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-building"></i> <Link to={'../admin/landing'} className={`LandingHeader__text-black-color`}>Landing</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-book"></i> <Link to={'../admin/planes'} className={`LandingHeader__text-black-color`}>Planes</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-file-invoice"></i> <Link to={'../admin/reportescursos'} className={`LandingHeader__text-black-color`}>Reportes de cursos</Link>
                      </div>                      
                      <div>
                        <i className="fa-solid fa-user"></i> <Link to={'../admin/ultimasesion'} className={`LandingHeader__profile-menu-text-color`}>Última sesión</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-user"></i> <Link to={'../admin/usuarios'} className={`LandingHeader__text-black-color`}>Usuarios</Link>
                      </div>
                      <div>
                        <i className="fa-solid fa-user"></i> <Link to={'../admin/profile'} className={`LandingHeader__text-black-color`}>Perfil de admin</Link>
                      </div>
                  </div>
                  <div onClick={logout} style={{cursor: "pointer"}}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                    <span className='ms-2'>
                    Cerrar sesión
                    </span>
                    </div>
                </div>
                </div>
            </div>
        </div>

      </div>
    
    </div>
  )
}
