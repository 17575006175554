import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Footer } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
/**
 * Creado por Equilibrio Agency 2024<br>
 * Página para mostrar contenido de Preguntas Frecuentes Placetopay
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const FaqPlacetopayPage = () => {
    const location = useLocation();


    const [searchCriteria, setSearchCriteria] =  useState(location.state?location.state:{});
    const searchAction = (keyword) => {
      setSearchCriteria({keyword});
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
    <div>
        <LandingHeader  searchAction={searchAction} />
        <div style={{marginTop: "75px"}}></div>
        <div className='row justify-content-center'>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "30px", marginBottom: "10px"}}>
                <h1 className='CourseDetail__title'>Preguntas frecuentes sobre pagos electrónicos (PLACETOPAY)</h1>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>En este documento, usted encontrará una 
                    sección de preguntas y respuestas que le ayudará a sus usuarios a aclarar sus dudas sobre los 
                    pagos electrónicos procesados a través de <strong>Placetopay.</strong>
                    Recuerde que debe incluirlas en su sitio web. </p>
            </div>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "15px"}}>
                <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>¿Qué es Placetopay?</p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>Placetopay es la plataforma de pagos 
                    electrónicos que usa <strong>(EANX)</strong> para procesar en línea las transacciones generadas en la tienda virtual
                    con las formas de pago habilitadas para tal fin.</p>
            </div>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "15px"}}>
                <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>¿Cómo puedo pagar?</p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>En la plataforma <strong>(EANX)</strong> usted
                    podrá realizar su pago con los medios habilitados para tal fin. 
                    Usted, de acuerdo a las opciones de pago escogidas por el comercio, podrá pagar a través de 
                    tarjetas de crédito Visa, American Express, Diners y MasterCard y Cuentas debito ahorro y corriente PSE.</p>
            </div>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "15px"}}>
                <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>¿Es seguro ingresar mis datos bancarios en este sitio web? </p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>Para proteger tus datos <strong>EANX</strong> delega en <strong>Placetopay</strong> la 
                 captura de la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares exigidos por
                 la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además tiene certificado de 
                 seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la 
                 encriptación de todos los datos hacia y desde el sitio; de esta manera te podrás sentir seguro a la hora de 
                 ingresar la información de su tarjeta. </p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>Durante el proceso de pago, en 
                    el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra 
                    de dirección cambia a color verde. Estas características son visibles de inmediato y dan garantía y confianza
                     para completar la transacción en <strong>Placetopay</strong>.</p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}><strong>Placetopay</strong> también cuenta con el 
                monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.</p>
            </div>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "15px"}}>
                <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>¿Puedo realizar el pago cualquier día y a cualquier hora?</p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>Si aún no has finalizado tu pago, podrás volver al paso inicial 
                    y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar la forma de pago. </p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}><strong>ESTABLECIMIENTO DE COMERCIO: el punto anterior aplica a la forma de pago, 
                    pero deberán mencionar las políticas de devolución que tenga la tienda para dar cumplimiento al artículo 51 
                    de la Ley del Estatuto del Consumidor.</strong></p>
            </div>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "15px"}}>
                <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>¿Pagar electrónicamente tiene algún valor para mí como comprador? </p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>No, los pagos electrónicos realizados a través de <strong>(EANX)</strong> no generan costos adicionales para el comprador. </p>
            </div>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "15px"}}>
                <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>¿Qué debo hacer si mi transacción no concluyó?</p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>En primera instancia, revisar si llegó un email de confirmación de la transacción 
                    a la cuenta de correo electrónico inscrita en el momento de realizar el pago, en caso de no haberlo recibido, 
                    deberás ingresar a la sección de <Link to="/contactanos" className="auth__link">contacto</Link> para confirmar
                    el estado de la transacción.</p>
            </div>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "15px", marginBottom: "40px"}}>
                <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>¿Qué debo hacer si no recibí el comprobante de pago?</p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>Por cada transacción aprobada a través de <strong>Placetopay</strong>, 
                    recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar. 
                </p>
                <p className='' style={{fontSize: "16px", fontWeight: 400, marginTop: "20px"}}>Si no lo recibes, podrás <Link to="/contactanos" className="auth__link">contactarnos</Link> para 
                  solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar.  
                </p>
            </div>
      
    </div>
        <Footer />
    </div>
  )
}
