import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
const landingImages = require.context('../../assets/images', true);

export const LandingHeaderB2B = ({searchAction}) => {
  
  const location = useLocation();
  const { pathname } = location;
  
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  window.addEventListener('scroll', function(){
    if(isActive){
       setActive(!isActive);
    }
});
  
  const handleTopHome = () => {
      window.scrollTo(0, 0);
  }
  return (
    <div className='px-4 px-sm-5 navbar-nav-scroll navbar-nav LandingB2BHeader__navbar'>
      <div className='row justify-content-between justify-content-lg-evenly justify-md-conent-center align-items-center text-center mt-2 mb-1'>
        <div className='col-3 d-flex justify-content-start'>
          <Link to="/empresas" onClick={handleTopHome}>
              <img alt='Ean logo' className='img-fluid' src={landingImages('./landingB2B/logoeanx.png')} style={{maxWidth:"200px", cursor: "pointer"}}/>
          </Link>
        </div>
        <div className='col-12 col-md-9 d-none d-lg-block'>
          <div className='row justify-content-end'>
            <div className='col-12 col-lg-6 col-xl-4 align-items-center text-center justify-content-end' style={{marginTop: '18px'}}>
            { pathname == '/empresas' && <Link to="/empresas/contacto">
                <div className='LandingB2BHeader__primary-button'>
                Quiero que me contacten
                </div>
              </Link> }
            </div>
          </div>
          </div>
          <div className='col-1 d-lg-none text-end d-block pe-0 me-3' onClick={toggleClass}>
            <i className="fa fa-bars"></i> 
          </div>
        </div>
  {
    isActive &&
      <div className='row justify-content-center py-3' >
        <div className='col-12'>
          <div className='row text-center pb-2 justify-content-center'>
          { pathname == '/empresas' && <Link to="/empresas/contacto" className='col-12 mt-3 LandingB2BHeader__primary-button'>Quiero que me contacten</Link> }
          </div>
        </div>

      </div>
  }
    </div>
  )
}
