import React from 'react'
import { useLocation } from 'react-router-dom';
import { RegisterPage } from '../register/RegisterPage';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página de Actualización de datos usuarios eanistas
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface UpdateData
 */
export const UpdateData = () => {
    const location = useLocation();
    const userID = location?.state?.eanuser.id;

    return (
        <div>
            <RegisterPage userID={userID} />
        </div>
    )
}
