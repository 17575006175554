import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';

const handleGoStore = (url, e) => {
    e.preventDefault();
    window.open(url, '_top')
  }
export const CarouselCard = ({carouselLink, carouselImg, carouselImgMobile, carouselVideo}) => {
    
  const getVideoUrl = videoUrl => {
    const regex = /[?&]v=([^&]+)/;
    const match = videoUrl.match(regex);
    if (match) return match[1];
    else return null;
  }
  const [iframeHeight, setIframeHeight] = useState('200');
  const iframeRef = useRef(null);
  const ajustarAlturaIframe = () => {
    if (iframeRef.current) {
      const iframeWidth = iframeRef.current.offsetWidth;
      const aspectRatio = 9 / 16;
      const newHeight = Math.floor(iframeWidth * aspectRatio);
      setIframeHeight(`${newHeight}px`);
    }
  };
  useEffect(() => {
    ajustarAlturaIframe();
    const handleResize = () => {
      ajustarAlturaIframe();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const height = `${(100 * 9) / 16}px`;
    return (
            <div className='col-12'>
                <div className='row m-0'>
                    <div className='col-12 p-0'>
                        { carouselVideo ? 
                        <div style={{ position: 'relative', width: '100%', height: 0 }} className='video-container'><iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${getVideoUrl(carouselVideo)}?controls=1&rel=0&showinfo=0`} title="YouTube video player" style={{ position: 'absolute', width: '100%', height: '100%', margin: 0, padding: 0, top: 0, left: 0 }} className='video' frameborder="0" allowfullscreen></iframe>
    </div>
     : <><div className='landing__container-promocarousel'>
                        <Link to={{}} onClick={(e) => handleGoStore(carouselLink, e)}><div className='landing__promocarousel'> </div></Link>
                        </div>
                        <img alt={'Promo EanX'} className="d-lg-block d-none CarouselHome__image" src={carouselImg} width="100%" height="100%" border="0" />
                        <img alt={'Promo EanX'} className="d-none d-md-block d-lg-none CarouselHome__image" src={carouselImg} width="100%" height="100%" border="0"/>
                        <img alt={'Promo EanX'} className="d-block d-md-none d-lg-none CarouselHome__image" src={carouselImgMobile} width="100%" height="100%" border="0"/></> }
                    </div>
                </div>
            </div>
  )
}
