import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton, Modal } from '@mui/material';
import { landingImages } from './../../components/profile/shared';
import '../../styles/components/controls/UIControls.scss'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página de aviso de login para poder iniciar curso
 * @param {event} openDialog Función para abrir el dialogo
 * @param {event} afterDialogClose Función para cerrar el diálogo
 * @function CourseDialogLogin
 */
export const CourseDialogLogin = ({openDialog, afterDialogClose, course}) => {
    const [showModalCourseLogin , setShowModalCourseLogin] = useState(false);
    const navigate = useNavigate();

    const handleLogin = () => {
        setShowModalCourseLogin(false);
        navigate('/ingreso');
    }
    const handlePurchase = () => {
        setShowModalCourseLogin(false);
        navigate("/registro",  { state: {course:{courseId: course.courseId, name: course['course.title'], price: course.price}}});
    }
    const handleJoinNow = () => {
        setShowModalCourseLogin(false);
        navigate('/planes');
    }
    const handleCloseModal = () => {
        setShowModalCourseLogin(false);
    }
    const closeCancelDialog = () => {
        setShowModalCourseLogin(false);
        if(afterDialogClose) afterDialogClose();
    }
    useEffect(() => {
        setShowModalCourseLogin(openDialog)
    }, [openDialog])

    return (
        <Modal
            open={showModalCourseLogin}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>

                <div className='row justify-content-center align-items-center'>
                    <div style={{height:'1.3em', position:'relative', margin:'0px -20px'}}>
                        <IconButton
                            aria-label="close"
                            onClick={closeCancelDialog}
                            sx={{
                                position: 'absolute',
                                right: 15,
                                top: -2,
                                color: '#6E7274',
                            }}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </IconButton>
                    </div>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./loginfirst.png')} style={{width: "120px", marginTop: "-7px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        {/*<div className='ProfilePage__modal-title'>Inicia sesión primero</div>*/}
                        <p className='ProfilePage__modal-text-description' >
                            <b>Accede a éste y todos nuestros cursos de forma ilimitada con cualquiera de nuestros planes.</b>
                        </p>
                        <div className='' style={{marginTop: "20px", textAlign: "center"}}>
                            <button
                                className='ProfilePage__modal-button-accept'
                                onClick={handlePurchase}
                            >
                                Comprar Curso
                            </button>
                        </div>
                        <div style={{marginTop: "10px", marginBottom: "40px", textAlign: "center"}}>

                            <button
                                className='ProfilePage__modal-button-cancel'
                                onClick={handleJoinNow}
                            >
                                Adquiere tu Plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>)
}
