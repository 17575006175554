import React from 'react'
import { SimpleFooter } from '../../footer/Footer'
import { AdminHeader } from '../plans/AdminHeader'
import { ReportsIndividualCoursesReviewsSection } from "./ReportsIndividualCoursesReviewsSection";
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección usuarios
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const ReportsIndividualCoursesReviewsPage = () => {
  return (
    <>
      <AdminHeader />
      <div style={{marginTop: "100px"}}></div>
      <ReportsIndividualCoursesReviewsSection></ReportsIndividualCoursesReviewsSection>
      <SimpleFooter></SimpleFooter>
    </>
  )
}
