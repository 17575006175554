import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useFetch } from '../../hooks/useFetch';
import { useForm as hookUseForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthFormContainerPage, PwdEye, SubmitFormButton } from '../controls/UIControls';
import { PasswordValidator } from '../controls/PasswordValidator';
import { AmountFormat } from '../controls/AmountFormat';
import * as CONSTANTS from '../../assets/constants';
import { useAuthToken } from '../../hooks/useAuthToken';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de registro con pasarela de pago
 * 
 * @param {string} userID ID de usuario a registrar
 * @param {Array} dataPlan Datos del plan a registrar
 * @param {Array} dataPlan Datos del curso a comprar
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface RegisterWithPaymentPage
 */
export const RegisterWithPaymentPage = ({userID, dataPlan, dataCourse}) => {
    const alphabetPattern = CONSTANTS.PATTERN_ALPHABET;
    const numericPattern = CONSTANTS.PATTERN_NUMERIC;
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;

    const numericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;

    const [data, handleFetch] = useFetch();
    const [couponActivate, setCouponActivate] = useState(false);
    const location = useLocation();
    const plan = dataPlan ? dataPlan : location.state?.plan;
    const course = dataCourse ? dataCourse : location.state?.course;
    useEffect(() => {
        window.scrollTo(0, 0);
        if(userID){
            const responsed = handleFetch({
                url: `users/eanx_users/${userID}`,
                method: 'GET',
            });
            responsed
                .then((r) => {
                        setValue("firstName", r.name);
                        setValue("lastName", r.lastname);
                        setValue("userEmail", r.email);
                        setValue("documentType", r.identificationType.toUpperCase());
                        setValue("userDocument", r.identificationNumber);
                        setValue("phone", r.phone);
                    },
                    error => {
                        console.log(error.errors);
                    })
        }
    }, []);
    const { register, handleSubmit, watch, setValue,
        control, formState: { errors }, setError,
        getValues, setFocus } = hookUseForm();
    const [showAge, setShowAge] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [confirmPasswordMatches, setConfirmPasswordMatches] = useState(false);
    const [registerError, setRegisterError] = useState(null);
    const [colphone, setColphone] = useState(false);
    const {getSessionUser} = useAuthToken();
    const [namePattern, setNamePattern] = useState(alphabetPattern);
    const [docPattern, setDocPattern] = useState(numericPattern);
    const [docMessage, setDocMessage] = useState(numericMessage);
    const [discount, setDiscount] = useState({});
    const [discountType, setDiscountType] = useState(-1);
    const [totalPrice, setTotalPrice] = useState(course ? course.price : plan.price);

    const setValidPassword = (isValid) =>  {
        setPasswordIsValid(isValid);
    }

    const password=watch('password','');
    const password2=watch('password2','');

    const navigator = useNavigate();
    // Función para revisar máscara de correo electrónico
    const maskEmail = email => {
        const [user,domain] = email.split('@');
        const userlen = user.length;
        let masked='';
        for(let i=0;i<userlen;i++) {
            masked+=(i===0 || i === 1 || i === userlen -2 || i === userlen -1)?user[i]:'*';
        }
        masked+='@'+domain;
        return masked;
    }

    useEffect(()=> {
        setConfirmPasswordMatches(password===password2);
    },[password, password2]);
    // Función para retornar datos obtenidos en formulario
    const getRequestData = (formValues) => {
        return {
            name: formValues.firstName,
            lastname: formValues.lastName,
            email: formValues.userEmail,
            documentType: formValues.documentType,
            document: formValues.userDocument,
            phone: formValues.phone,
            address: formValues.address,
            password: formValues.password,
            type: 1,
            registerPay: 2
        }
    }
    const isValidPhone = (phone) => {
        if(phone.startsWith('57')){
            if(parseInt(phone[2]) === 3 && parseInt(phone.length) === 12){
                setColphone(false);
            }else{
                setColphone(true);
            }
        }else{
                setColphone(false);
            }
      };
    // Función retorno al verificar usuario
    const afterUserVerified = (usr) => {
        const user = getSessionUser();
        if(user!==undefined) {
            switch(user.type) {
                case CONSTANTS.USER_TYPE_EAN_STUDENT:
                case CONSTANTS.USER_TYPE_EAN_PAYROLL_TEACHER:
                case CONSTANTS.USER_TYPE_EAN_SUBJECT_TEACHER:
                case CONSTANTS.USER_TYPE_EAN_COLLABORATOR:
                    if(usr.identificationNumber == user.document){
                        navigator('/cuenta-creada', {state:{user, plan: usr.plan}});
                    }
                    break;
            }
        } else {
            navigator(`../actualizar/${usr.id}`, { state: { plan: usr.plan, eanuser: usr } });
        }
    }
    // Handle para guardar datos de registro y verificación de correo electrónico
    const HandleRegister = (formValues) => {
        setRegisterError(null);
        if(passwordIsValid && confirmPasswordMatches && !isValidPhone(formValues.phone)) {
            const response = handleFetch({
                url: `users/eanx_users?identificationNumber=${formValues.userDocument}`,
                method: 'GET',
            });
            response
                .then((r) => {
                        afterUserVerified(r);
                    },
                    error => {
                        const response = handleFetch({
                            url:'users',
                            method: 'POST', 
                            data: getRequestData(formValues)
                        });
                        response
                            .then(r => {
                                navigator('/verificar-email',{state: {email: getValues('userEmail'), changeEmail: false, registerPay: true,plan,course,discount}});
                            },
                            error => {
                                if(error.errors) {
                                    let e = error.errors[0];
                                    switch(e.id) {
                                        case 201: // Correo ya registrado
                                            setError('userEmail',{message:CONSTANTS.MESSAGE_EMAIL_REGISTERED},{shouldFocus:true});
                                            break;
                                        case 202: // Documento ya registrado
                                            setError('userDocument',{message: CONSTANTS.MESSAGE_DOCUMENT_REGISTERED.replace('%1',maskEmail(error.data.email))});
                                            setFocus('userDocument');
                                            break;
                                        default:
                                            break;
                                    }
                                } else {
                                    setRegisterError( CONSTANTS.MESSAGE_GENERIC_ERROR);
                                }
                            });
                    })
        } else {
            alert('Hay errores en el formulario');
        }
    }
    function calcFinalPrice(price, type, value) {
        let finalPrice = price;
        if (type === 0) finalPrice = price - value;
        else if (type === 1) {
          const porcDiscount = (price * value) / 100;
          finalPrice = price - porcDiscount;
        } else {
          console.error('Tipo de cálculo no válido. Debe ser 0 o 1.');
        }
        if(finalPrice<0) finalPrice = price; 
        return finalPrice;
      }
    // Función activar cupón
    const activateCoupon = (e) => {
        e.preventDefault();
        let aux = getValues('coupondiscount')
        const response = handleFetch({
            url: `payments/discounts?&code=${aux}`,
            method: 'GET',
        });
        response
            .then((r) => {
                    if(r?.code){
                        setCouponActivate(false);
                        setDiscount(r);
                        setDiscountType(r.type);
                        setTotalPrice(calcFinalPrice(course ? course.price : plan.price,r.type,r.price));
                    }else{
                        setDiscount({price: 0})
                        setDiscountType(-1)
                        setTotalPrice(course ? course.price : plan.price);
                        setCouponActivate(true);
                    }
                })
    }
    const handleError = (e) => console.log(errors,e);
    // Función validar tipo de documento
    const handleSelectChange = (selectedOption) => {
        if(selectedOption.target.value === 'DIE' || selectedOption.target.value === 'PA' || selectedOption.target.value === 'TI') {
            setShowAge(true);
        }else {
            setShowAge(false);
        }
        if(selectedOption.target.value === 'PA') {
            setDocPattern(alphanumericPattern);
            setDocMessage(alphanumericMessage);
        } else {
            setDocPattern(numericPattern);
            setDocMessage(numericMessage);
        }
        //trigger('userDocument');
    }
    const registerWithPaymentForm = (
        <form onSubmit={ handleSubmit(HandleRegister, handleError) }>

            <div className='row justify-content-center'>
                <div className='col-12 col-sm-6'>
                    <div className="auth__title-register">TU CUENTA</div>
                    <div className="mb-3">
                        <label className='auth__form-label'>Correo <span className='auth__required-input'>*</span> <span className='auth__comment'>Será tu usuario en plataforma</span></label>
                        <input
                            type='text'
                            name='userEmail'
                            disabled={userID}
                            className={`form-control auth__input 
                                ${errors.userEmail?"auth__input--error":""}`}
                            placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                            {...register("userEmail", { required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                pattern: {
                                    value:  CONSTANTS.PATTERN_EMAIL ,
                                    message:  CONSTANTS.MESSAGE_EMAIL_INVALID
                                }})}
                        />
                        {errors.userEmail && (
                            <span role="alert" className='auth__inputError'>
                    {errors.userEmail.message}
                    </span>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className='auth__form-label'>Contraseña <span className='auth__required-input'>*</span></label>
                        <Controller
                            name="password"
                            control={control}
                            rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                            render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                                return (
                                    <PwdEye
                                        className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                                        placeholder={CONSTANTS.LABEL_PASSWORD_PLACEHOLDER}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        name={name}
                                        error={invalid}
                                    >
                                    </PwdEye>)
                            }
                            }
                        />
                        <PasswordValidator
                            value={password}
                            checkCriteria={setValidPassword}
                        ></PasswordValidator>
                        {!passwordIsValid && (
                            <span role="alert" className='auth__inputError'>
                    {CONSTANTS.MESSAGE_PASSWORD_IVALID}
                    </span>
                        )}
                    </div>
                    <div className="mb-3">
                        <label className='auth__form-label'>Repite tu contraseña <span className='auth__required-input'>*</span></label>
                        <Controller
                            name="password2"
                            control={control}
                            rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                            render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                                return (
                                    <PwdEye
                                        className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                                        placeholder={CONSTANTS.LABEL_PASSWORD_CONFIRM_PLACEHOLDER}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        name={name}
                                        error={invalid}
                                    >
                                    </PwdEye>)
                            }
                            }
                        />
                        {!confirmPasswordMatches && (
                            <span role="alert" className='auth__inputError'>
                    {CONSTANTS.MESSAGE_PASSWORD_CONFIRM_DOESNT_MATCH}
                    </span>
                        )}
                    </div>


                    <div className="auth__title-register">DATOS PERSONALES</div>
                    <div className="mb-3">
                        <label className='auth__form-label'>Nombres <span className='auth__required-input'>*</span></label>
                        <input
                            type='text'
                            name='firstName'
                            className={`form-control auth__input 
                                ${errors.firstName?"auth__input--error":""}`}
                            placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                            {...register("firstName", {
                                required: CONSTANTS.MESSAGE_NAME_REQUIRED,
                                pattern: {
                                    value: namePattern,
                                    message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                } })}
                        />
                        {errors.firstName && (
                            <span role="alert" className='auth__inputError'>
                    {errors.firstName.message}
                    </span>
                        )}
                    </div>
                    <div className="mb-3">
                        <label className='auth__form-label'>Apellidos <span className='auth__required-input'>*</span></label>
                        <input
                            type='text'
                            name='lastName'
                            className={`form-control auth__input 
                                ${errors.lastName?"auth__input--error":""}`}
                            placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                            {...register("lastName", { required: CONSTANTS.MESSAGE_LASTNAME_REQUIRED,
                                pattern: {
                                    value: namePattern,
                                    message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                } })}
                        />
                        {errors.lastName && (
                            <span role="alert" className='auth__inputError'>
                    {errors.lastName.message}
                    </span>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className='auth__form-label'>Tipo de documento </label>
                        <select
                            defaultValue=""
                            className={`form-select auth__select 
                                ${errors.documentType?"auth__input--error":""}`}
                            aria-label="Default select example"
                            name='documentType'
                            disabled={userID}
                            {...register("documentType", { required: CONSTANTS.MESSAGE_DOCUMENT_TYPE_REQUIRED,
                                onChange: handleSelectChange  })}
                        >
                            <option value="">Selecciona el tipo de documento</option>
                            <option value="CC">Cédula de Ciudadanía</option>
                            <option value="CE">Cédula de Extranjería</option>
                            <option value="DIE">Documento de Identidad Extranjera</option>
                            <option value="PA">Pasaporte</option>
                            <option value="TI">Tarjeta de Identidad</option>
                            <option value="PEP">Permiso Especial de Permanencia</option>
                        </select>
                        {errors.documentType && (
                            <span role="alert" className='auth__inputError'>
                    {errors.documentType.message}
                    </span>
                        )}
                    </div>
                    {
                        showAge ? (
                            <div className='show-form-age'>
                                <div className="form-check">
                                    <input className="form-check-input auth__form-check"
                                           type="checkbox"
                                           value=""
                                           {...register("olderThan12", {required: CONSTANTS.MESSAGE_OLDER_THAN_12_REQUIRED})}
                                    />
                                    <label className='form-check-label auth__form-label' style={{fontSize: "12px"}}>
                                        Declaro ser mayor de 12 años.
                                    </label>
                                </div>
                                <div>
                                    <label className='form-check-label auth__form-label' style={{fontSize: "12px", paddingBottom: "12px"}}>
                                        Si eres menor de 12 años requieres autorización expresa de tus padres o tutores,
                                        pídeles que se comuniquen con nosotros <Link to='/contactanos' className='text_primary'>aquí.</Link>
                                    </label>
                                </div>
                                {errors.olderThan12 && (
                                    <div role="alert" className='auth__inputError'>
                                        {errors.olderThan12.message}
                                    </div>
                                )}
                            </div>
                        ) : null
                    }

                    <div className="mb-3">
                        <label className='auth__form-label'>Número de documento <span className='auth__required-input'>*</span></label>
                        <input
                            type='text'
                            name='userDocument'
                            disabled={userID}
                            className={`form-control auth__input 
                                ${errors.userDocument?"auth__input--error":""}`}
                            placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                            {...register("userDocument", {
                                required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED,
                                pattern: {
                                    value: docPattern,
                                    message: docMessage
                                } })}
                        />
                        {errors.userDocument && (
                            <span role="alert" className='auth__inputError'>
                    {errors.userDocument.message}
                    </span>
                        )}
                    </div>


                    <div className="mb-3">
                        <label className='auth__form-label'>Teléfono móvil<span className='auth__required-input'>*</span></label>
                        <Controller
                            name="phone"
                            control={control}
                            className="auth__select"
                            rules={{ required: CONSTANTS.MESSAGE_PHONE_REQUIRED }}
                            render={({ field }) =>
                                <PhoneInput {...field}
                                            inputClass={`auth__input-phone 
                                ${errors.phone?"auth__input--error":""}`}
                                            specialLabel=''
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                placeholder: CONSTANTS.LABEL_PHONE_PLACEHOLDER
                                            }}
                                            country={'co'}/>}
                        />
                        {errors.phone && (
                            <span role="alert" className='auth__inputError'>
                    {errors.phone.message}
                    </span>
                    )}
                    {colphone && (
                        <span role="alert" className='auth__inputError'>Tu número colombiano debe iniciar en 3 y tener el formato 3## #######</span>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className='auth__form-label'>Dirección <span className='auth__required-input'>*</span></label>
                        <input
                            type='text'
                            name='address'
                            className={`form-control auth__input 
                                ${errors.address?"auth__input--error":""}`}
                            placeholder={CONSTANTS.LABEL_ADDRESS_PLACEHOLDER}
                            {...register("address", { required: CONSTANTS.MESSAGE_ADDRESS_REQUIRED })}
                        />
                        {errors.address && (
                            <span role="alert" className='auth__inputError'>
                    {errors.address.message}
                    </span>
                        )}
                    </div>

                    <div className="form-check mt-5">
                        <input className="form-check-input auth__form-check"
                               type="checkbox"
                               value=""
                               {...register("acceptTerms", {required: CONSTANTS.MESSAGE_ACCEPT_TERMS_REQUIRED})}
                        />
                        <label className='form-check-label auth__form-label' style={{fontSize: "12px"}} >
                            Acepto <a href='https://eanx.co/terminos-condiciones.pdf' className='auth__link'>Términos y Condiciones</a> de la plataforma.
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input auth__form-check"
                               type="checkbox"
                               value=""
                               {...register("acceptPolicy", {required:CONSTANTS.MESSAGE_ACCEPT_POLICY_REQUIRED})}
                        />
                        <label className='form-check-label auth__form-label' style={{fontSize: "12px"}} >
                            Tengo a disposición la <a href='https://universidadean.edu.co/sites/default/files/institucion/acuerdos/politica-tratamiento-de-datos-personales.pdf' className='auth__link'>Política de tratamiento de datos personales</a> y el <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/aviso-de-privacidad' className='auth__link'>Avíso de privacidad</a> de la Universidad Ean
                            y con fundamento en ello, <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/autorizacion-para-uso-de-datos-personales' className='auth__link'>autorizo</a> a la Universidad Ean de manera voluntaria, explícita, informada e inequívoca el
                            tratamiento de mis datos personales.
                        </label>
                    </div>
                    {errors.acceptTerms && (
                        <div role="alert" className='auth__inputError'>
                            {errors.acceptTerms.message}
                        </div>
                    )}
                    {errors.acceptPolicy && (
                        <div role="alert" className='auth__inputError'>
                            {errors.acceptPolicy.message}
                        </div>
                    )}
                    <SubmitFormButton main_title="Continuar" wait_title="Procesando..." loading={data.loading}></SubmitFormButton>

                    {registerError && (
                        <div role="alert" className='auth__inputError text-center'>
                            { CONSTANTS.MESSAGE_GENERIC_ERROR}
                        </div>
                    )}



                </div>

                <div className='col-12 col-sm-6 mt-4'>

                    <div className='PaymentType__table-container mt-4 mb-3'>
                        <table className='PaymentType__table'>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>{course ? course.name : plan.name}</td>
                                <td className='PaymentType__td-right'><AmountFormat
                                    name='price'
                                    value={course ? course.price : plan.price}
                                    displayType='text'
                                    currency='COP'
                                /></td>
                            </tr>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>Descuento</td>
                                <td className={`PaymentType__td-right ${discountType !== -1?'ProfilePage__color-green':''}`}><AmountFormat
                                    name='discount'
                                    value={discount?.price || 0}
                                    displayType='text'
                                    currency={discountType===1?'':'COP'}
                                    percentage={discountType===1}
                                /></td>
                            </tr>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>Total</td>
                                <td className='PaymentType__td-right'><AmountFormat
                                    name='total'
                                    value={totalPrice}
                                    displayType='text'
                                    currency='COP'
                                /></td>
                            </tr>
                        </table>
                    </div>

                    <div className="mt-4">
                        <label className='auth__form-label'>Cupón de descuento</label>
                        <div className="input-group">
                            <input
                                type='input'
                                name='coupondiscount'
                                className={`form-control auth__input`}
                                placeholder={CONSTANTS.LABEL_MESSAGE_DISCOUNT}
                                {...register("coupondiscount")}
                            />
                            <span className='input-discount-text' onClick={(event) => activateCoupon(event)}>
                            Activar
                        </span>
                        </div>
                        {couponActivate && (
                            <span role="alert" className='auth__inputError'>
                                El cupón {getValues('coupondiscount')} NO es válido
                            </span>
                        )}
                        {discount && !couponActivate && (
                            <span role="alert" className={`${discount.price>0?'ProfilePage__color-green':''}`}>
                                Se ha aplicado el cupón {getValues('coupondiscount')}
                            </span>
                        )}
                    </div>


                </div>
            </div>
        </form>

    );
    return (
        <AuthFormContainerPage title={plan?.name || course?.name || 'Registro'} content={registerWithPaymentForm} containerSubClass='full-width' registerPay={1}></AuthFormContainerPage>
    )
}