import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { AcceptDialog, Toast } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección certificados
 * @interface CertificationSection
 */
export const CertificationSection = () => {

    const [certificates, setCertificates] = useState([]);
    const [certificatesFull, setCertificatesFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [showModalSync, setShowModalSync] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedVar, setSelectedVar] = useState({});
    const location = useLocation();
    const { pathname } = location;

    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const [dataFilter, setDataFilter] = useState({
        name: '',
        type: '',
        since: '',
        active: '',
    })
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || { show: false });
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filteractive = dataFilter.active !== '' ? "&active=" + dataFilter.active : ''
        const response = handleFetch({
            url: `bo/certificates?${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificates(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/certificates?$limit=50000${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setCertificatesFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filteractive = dataFilter.active !== '' ? "&active=" + dataFilter.active : ''
        const response = handleFetch({
            url: `bo/certificates?${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificates(r.data)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/certificates?$limit=50000${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setCertificatesFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const vars = [
        {
            "name": "certificates",
            "title": "Certificados",
        },
        {
            "name": "badges",
            "title": "Insignias",
        },
        {
            "name": "courses",
            "title": "Cursos",
        },
        {
            "name": "certificates",
            "title": "Progreso de usuarios",
        },
        {
            "name": "",
            "title": "Error",
        }
    ]
    // Función mostrar modal para activar sincronización de certificados, insignias, cursos e usuarios
    const handleOpenModalSync = (id) => {
        setSelectedVar(vars.find(variant => variant.name === id) || vars[4]);
        setShowModalSync(true);
        setSelectedOption(id);
    }
    // Función ocultar modal para activar sincronización de certificados, insignias, cursos e usuarios
    const handleCloseModalSync = () => {
        setShowModalSync(false);
        setSelectedOption('');
        setSelectedVar({});
    }
    // Handle para sicronizar cursos, certificados e insignias
    const handleSync = async (event) => {
        event.preventDefault();
        await handleFetch({
            url: `bo/sync${selectedOption}`,
            method: 'POST',
            data: {},
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(async () =>  {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha completado la sincronización exitosamente.',
                    position: { horizontal: 'left' },
                });
                setShowModalSync(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al realizar la sincronización. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
            setShowModalSync(false);
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Certificados</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/certicertika'} className={`${pathname == '/admin/certicertika' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados Certika</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/certieanx'} className={`${pathname == '/admin/certieanx' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados EanX</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/certigenerados'} className={`${pathname == '/admin/certigenerados' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados Generados</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listcertifications.csv"}
                            onSubmit={handleSubmit}
                            data={certificatesFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre de curso</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de reconocimiento</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='type'
                                        value={dataFilter?.type || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="1">Certificado</option>
                                        <option value="2">Insignia</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Estado</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='active'
                                        value={dataFilter?.active || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="1">Activos</option>
                                        <option value="0">Inactivos</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalSync('certificates', event)}>
                                    <span>Sinc. Certificados</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalSync('badges', event)}>
                                    <span>Sinc. insignias</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalSync('courses', event)}>
                                    <span>Sinc. Cursos</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalSync('users', event)}>
                                    <span>Sinc. Prog. Usuarios</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "1px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable certificates={certificates} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={showModalSync}
                onClose={handleCloseModalSync}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSync}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Sincronizar {selectedVar?.title}</div>
                                <div className="mt-2 mb-2 B2BAdmin__text-description"><span className='auth__required-input'>Esta operación va a modificar información sensible de la base de datos, debe estar completamente seguro de efectuar esta acción.</span></div>
                                <div className='mt-3 mb-3 text-center'>¿Desea continuar con la operación?</div>
                                
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">SI</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalSync}>NO</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} certificates Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ certificates, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name": "NOMBRE CURSO",
            "sort_name": "title",
            "width": "35%"
        },
        {
            "name": "PLANTILLA DE CERTIFICADO",
            "sort_name": "template",
            "width": "25%"
        },
        {
            "name": "RECONOCIMIENTO",
            "sort_name": "type",
            "width": "15%"
        },
        {
            "name": "ESTADO",
            "sort_name": "type",
            "width": "8%"
        },
        {
            "name": "FECHA CREACIÓN",
            "sort_name": "date",
            "width": "12%"
        }
    ];

    const [certificatesSorted, setCertificatesSorted] = useState(certificates)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(certificates.length).fill(false))
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedId, setSelectedId] = useState(0);
    const [dialogProperties, setDialogProperties] = useState({ open: false });
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }

    useEffect(() => {
        setCertificatesSorted(certificates)
        setTotalSorted(total)
    }, [certificates, total])

    const resetState = () => {
        setUserHovered(Array(certificates.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(certificates.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filteractive = dataFilter.active !== '' ? "&active=" + dataFilter.active : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/certificates?&$skip=${result}${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificatesSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    // Función handle para selección de selector
    const handleSelectPlanChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
    };
    // Función mostrar modal para cambiar estado de certificados e insignias
    const handleOpenModalChangeStatus = (id) => {
        setShowModalChangeStatus(true);
        setSelectedId(id);
    }
    // Función ocultar modal para cambiar estado de certificados e insignias
    const handleCloseModalChangeStatus = () => {
        setShowModalChangeStatus(false);
        setSelectedId(0);
    }
    // Handle para cambiar estado de certificados e insignias (Activar / Desactivar)
    const handleChangeStatus = (event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/certificates/${selectedId}`,
            method: 'PATCH',
            data: { active: parseInt(selectedOption) },
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha cambiado el estado exitosamente.',
                    position: { horizontal: 'left' },
                });
                setShowModalChangeStatus(false);
            })
            .catch(() => {
                console.log("entro aqui")
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al cambiar el estado del certificado / insignia. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalChangeStatus(false);
    }
    // Handle para sicronizar cursos, certificados e insignias
    const handleSync = (event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/certificates/${selectedId}`,
            method: 'PATCH',
            data: { active: parseInt(selectedOption) },
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha cambiado el estado exitosamente.',
                    position: { horizontal: 'left' },
                });
                setShowModalChangeStatus(false);
            })
            .catch(() => {
                console.log("entro aqui")
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al cambiar el estado del certificado / insignia. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalChangeStatus(false);
    }
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalChangeStatus}
                onClose={handleCloseModalChangeStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleChangeStatus}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Cambiar Estado (Certificado / Insignia)</div>
                                <select name="selectedOption" className="form-select PlanFormContainer__input mb-3" defaultValue={selectedOption ? selectedOption : ''} onChange={handleSelectPlanChange}>
                                    <option value='' key=''>Selecciona un estado</option>
                                    <option value='1' key='1'>Activar</option>
                                    <option value='0' key='0'>Desactivar</option>
                                </select>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Cambiar Estado</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalChangeStatus}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <AcceptDialog {...dialogProperties}></AcceptDialog>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        certificatesSorted.length != 0 ?
                        certificatesSorted.map((cert, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert['course.title']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert.certificationTemplateId}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.type == 1 ? 'Certificado' : 'Insignia'}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.active == 1 ? 'Activo' : 'Inactivo'}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{new Date(cert.createdAt).toISOString().split('T')[0]}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={{}} onClick={(event) => handleOpenModalChangeStatus(cert.courseId, event)}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}