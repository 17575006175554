import React, { useEffect } from 'react';
import { LandingHeaderB2B } from './LandingHeaderB2B'
import { Footer } from '../footer/Footer';

const landingImages = require.context('../../assets/images', true);

export const LandingThanksPage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    return (
    <>
        <LandingHeaderB2B />
        <div className='container-fluid landing__HomeSection'>
            <div className='container my-5 py-5 py-sm-2'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12 col-lg-7 d-flex pe-0 d-lg-grid mb-3'>
                            <img alt='Cursos digitales que se adaptan a tu empresa' src={landingImages('./landingB2B/gracias.png')} className="img-fluid" width="auto" height="80%" />
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-10 col-12'>
                            <div className='row'>
                                <div className='col-12 text-center mb-3 mt-2'>
                                    <img alt='Gracias' src={landingImages('./landingB2B/corazon.png')} width="80" height="60" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 landingb2b__title-thanks'>
                                ¡GRACIAS POR TU INTERÉS EN EANX!
                                </div>
                            </div>
                            <div className='row my-4'>
                                <div className='col-12 landingb2b__paragraph-text-thanks landingb2b__white mb-2'>
                                Muy pronto nos comunicaremos contigo para comenzar. Mientras tanto, si tienes preguntas,<br /> 
                                <span class="fw-bold">¡no dudes en contactarnos!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <Footer />
    </>
  )
}
