import React from 'react';
import { AdminHeader } from '../plans/AdminHeader';
import { SimpleFooter } from '../../footer/Footer';
import { B2BCreateEditSection } from './B2BCreateEditSection';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección edición de usuarios
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const B2BCreateEditPage = () => {
    return (
        <>
            <AdminHeader />
            <div style={{marginTop: "100px"}}></div>
            <B2BCreateEditSection />
            <SimpleFooter />
        </>
    )
}