import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AuthFormContainerPage } from '../controls/UIControls'

const landingImages = require.context('../../assets/images', true)
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar al reestablecer contraseña vía email
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface PasswordMessageSentPage
 */
export const PasswordMessageSentPage = () => {
    const location = useLocation();
    const properties = {
        title: "Restablecer Contraseña",
        content: (<>
                    <p className='mt-3' style={{overflowWrap: "anywhere"}}>Para restablecer tu contraseña, hemos enviado<br /> un correo 
                    a la cuenta registrada <span style={{fontWeight: "700"}}>{location.state.email}</span>.</p>
                    <p>No olvides revisar la carpeta de correos no deseados / SPAM
                    en caso de no recibirlo en tu buzón principal.</p>
                    <p>Si tu problema persiste por favor <Link to="/contactanos" className="auth__link">Contáctanos</Link></p>
                    <p>Si ya cambiaste tu contraseña, puedes <Link to='/ingreso' className='text_primary fw-bold'>volver a iniciar sesión</Link></p>
                </>)
    }
    return <AuthFormContainerPage {...properties}></AuthFormContainerPage>
}
