import React from 'react'
import { SimpleFooter } from '../../footer/Footer'
import { AdminHeader } from './AdminHeader'
import { PlandCourseSection } from "./PlandCourseSection";
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección de gestión de cursos para B2B
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const PlandCoursePage = () => {
  return (
    <>
      <AdminHeader />
      <div style={{marginTop: "100px"}}></div>
      <PlandCourseSection />
      <SimpleFooter></SimpleFooter>
    </>
  )
}
