import React from 'react'
import { CarouselCard } from './CarouselCard';
import { Carousel } from 'react-responsive-carousel';

const landingImages = require.context('../../assets/images', true);
const arrowStyles ={
    position: "absolute",
    backgroundColor: "transparent",
    border: "none",
    zIndex: 2,
  
  };
  const indicatorStyles = {
    background: '#6E7274',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: "100px"
  };
  
export const CarouselHomeLanding = ({dataCarousel}) => {
    const carousel_properties = {
        preventMovementUntilSwipeScrollTolerance: true,
        showStatus: false,
        swipeScrollTolerance: 100,
        swipeable: true,
        showThumbs: false,
        autoPlay: true,
        interval: 90000,
        transitionTime: 1000,
        infiniteLoop: true
    }
    const carousel_properties_mobile = {
        preventMovementUntilSwipeScrollTolerance: true,
        showStatus: false,
        swipeScrollTolerance: 100,
        swipeable: true,
        showThumbs: false,
        autoPlay: true,
        interval: 4000,
        transitionTime: 1000,
        infiniteLoop: true
    }
    return (
        <div>
            
            <Carousel className='d-none d-lg-block' {...carousel_properties}
            
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                        <button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            style={{
                            ...arrowStyles,
                            left: "1em",
                            top: "50%"

                            }}
                        >
                            <img alt='Prev arrow' src={landingImages(`./icons/left-arrow-icon.svg`)} className='SuccessfulCases__image' />                         
                        </button>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <button
                                type="button"
                                onClick={onClickHandler}
                                title={label}
                                style={{
                                ...arrowStyles,
                                right: "1em",
                                top: "50%"
                                }}
                            >
                                <img alt='Next arrow' src={landingImages(`./icons/right-arrow-icon.svg`)} className='SuccessfulCases__image' />                         
                            </button>
                            )
                    }

                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                        return (
                            
                            <li
                                style={{ ...indicatorStyles, background: '#3BAC53' }}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`}
                            />
                        );
                    }
                    return (
                        <li
                            style={indicatorStyles}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`}
                        />
                    );
                    }}
            >
                    {
                    dataCarousel.map( bestCourse => (
                        <CarouselCard key={bestCourse.id} {...bestCourse} />
                    ))
                } 
                                        
            </Carousel>



            <Carousel className='d-block d-sm-none'  {...carousel_properties_mobile}
            
            showArrows={false}
            showIndicators={false}
            
            
            >                    

                  
                                        
            </Carousel>
            <Carousel className='d-none d-sm-block d-lg-none'  {...carousel_properties_mobile}


            showArrows={false}

            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                  return (
                    
                      <li
                          style={{ ...indicatorStyles, background: '#3BAC53' }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                      />
                  );
              }
              return (
                  <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                  />
              );
            }}

            >

                
                    {
                        dataCarousel.map( bestCourse => (
                            <CarouselCard key={bestCourse.id} {...bestCourse} />
                        ))
                    }
                                        
            </Carousel>

            <Carousel className='d-block d-sm-none'  {...carousel_properties_mobile}
            
            showArrows={false}
            showIndicators={false}
            
            
            >                    

                {
                    dataCarousel.map( bestCourse => (
                        <CarouselCard key={bestCourse.id} {...bestCourse} />
                    ))
                }   
                                        
            </Carousel>
        </div>
    )
}
export { CarouselHomeLanding as default } from "./CarouselHomeLanding.js";
