import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { CSVLink } from 'react-csv';
import { Toast } from '../../controls/UIControls';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado sección usuarios
 * @interface B2BAdminSection
 */
export const B2BAdminSection = () => {

    const [companies, setCompanies] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const location= useLocation();
    const [toastProperties, setToastProperties] = useState({show: false});
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }
    const {authToken}= useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || {show:false});
        const response = handleFetch({
            url:`bo/companiesb2b`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                    setCompanies(r.data)
                    setTotal(r.total)
                },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%"}}>Empresas</h1>
                        </div>
                        <div className='col-12 text-end' style={{marginTop: "30px", marginRight: "10%"}}>
                        <Link className='PlanAdmin__create-button' to='/admin/b2b/crear'>
                                <i className='fa-solid fa-plus' style={{marginRight: "15px"}}></i>
                                <span>Crear</span>
                            </Link>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{width: "90%", marginTop: "17px", overflowX: "auto"}}>
                            {
                                data.loading?
                                    <Loading />
                                    :
                                    <CompaniesTable companies={companies} total={total} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} companies Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function CompaniesTable
 */
const CompaniesTable = ({companies, total}) =>{

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name":"EMPRESA",
            "sort_name":"title",
            "width": "20%"
        },
        {
            "name":"INFORMACIÓN",
            "sort_name":"enabled",
            "width": "45%"
        },
        {
            "name": "FECHA CREACIÓN",
            "sort_name": "highlighted",
            "width":"15%"
        },
        {
            "name": "FECHA INICIO",
            "sort_name": "highlighted",
            "width":"15%"
        },
    ];

    const [companiesSorted, setCompaniesSorted] = useState(companies)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(companies.length).fill(false))

    useEffect(() => {
        setCompaniesSorted(companies)
        setTotalSorted(total)
    }, [companies, total])

    const resetState = () =>{
        setUserHovered(Array(companies.length).fill(false))
    }

    const handleActiveRow = (index)=>{
        let auxArray = Array(companies.length).fill(false);
        auxArray[index] =  true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken }= useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        setPage(event.selected)
            const response = handleFetch({
                url: `bo/webusers?$skip=${result}`,
                method: 'GET',
                headers: {Authorization: `Bearer ${authToken}`}
            });
            response
                .then((r) => {
                        setCompaniesSorted(r.data)
                        setTotalSorted(r.total)
                    },
                    error => {
                        console.log(error.errors);
                        if(error.errors) {

                        }
                    })
    };
    return(
        <>
        <table className='UserAdmin__table' >
            <thead className='UserAdmin__table-header' style={{height: "52px"}}>
            <tr style={{cursor: "pointer"}}>
                {headers.map((header, index)=>{
                    return(
                        <th key={index} className="overflow" style={{width: header.width, paddingLeft: "16px"}}>{header.name}</th>
                    )
                })}
                <th style={{width: "10%"}}></th>
            </tr>
            </thead>
            <tbody>
            {
                companiesSorted.length!=0 ?
                    companiesSorted.map((user, index)=>{
                        return(
                            <tr onMouseLeave={resetState} onMouseEnter={()=>{handleActiveRow(index)}} className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{user.name}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}><div className="UserAdmin__table-td">{user.description}</div></td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{new Date(user.createdAt).toISOString().split('T')[0]}</td>
                                <td className="overflow" style={{paddingLeft: "16px"}}>{new Date(user.startsAt).toISOString().split('T')[0]}</td>
                                {
                                    userHovered[index]?
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <div className='UserAdmin__button-container' >
                                                    <Link to={{
                                                        pathname: `/admin/b2b/editar/${user.id}`
                                                    }}>
                                                        <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                    </Link>

                                                </div>
                                            </div>
                                        </td>
                                        :
                                        <td></td>
                                }
                            </tr>
                        );
                    })
                    :
                    <tr>
                        <td colSpan="6" style={{padding: "16px", textAlign: "center"}}><strong>No se encontraron registros</strong></td>
                    </tr>
            }
            </tbody>
        </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
    </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{minHeight: "300px"}}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage}/>
            </div>
        </div>
    );
}