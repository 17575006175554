import { useState } from "react";
import { useFetch } from "./useFetch";
// Hook para gestión de cursos individuales comprandos por los usuarios
export const useUserCourse = () => {
    const [data, handleFetch] = useFetch();
    const [hasCourse, setHasCourse] = useState(true);

    const resolver = (resolve, nv) => (
        (pv) => {
            resolve(nv);
            return nv;
        });
    const checkUserCourse =  (accessToken = null, userId, companyId, course) => {
        return new Promise(resolve => {
            if(!!!accessToken) {
                setHasCourse(resolver(resolve, false));
            } else {
                handleFetch({
                    url: 'users/plans/users_courses/current',
                    method: 'POST',
                    data: {
                        companyId,
                        userId,
                        courseId: course.courseId,
                    },
                    headers: {Authorization: `Bearer ${accessToken}`}
                })
                .then ((p)=> {setHasCourse(resolver(resolve,p))})
                .catch(()=> setHasCourse(resolver(resolve, false)))
            }
        })
    }
    return {checkUserCourse,hasCourse};
}