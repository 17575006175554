import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { Link, useLocation } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { CSVLink } from 'react-csv';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado sección Reportes
 * @interface ReportsAdminReviewsSection
 */
export const ReportsAdminReviewsSection = () => {

    const [users, setUsers] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [dataFilter, setDataFilter] = useState({
        document: '',
        email: '',
        lastname: '',
        name: '',
        requestId: '',
        errorType: '',
    })
    const { authToken } = useAuthToken(true);
    const location = useLocation();
    const { pathname } = location;
    // Consulta de endpoint reportes de pagos    
    useEffect(() => {
        let filterrequestId = dataFilter.requestId !== '' ? `&requestId=` + dataFilter.requestId : ''
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filterstype = dataFilter.errorType !== '' ? "&errorType[$eq]=" + dataFilter.errorType : ''
        const response = handleFetch({
            url: `bo/users_plans_review?${filterstype}${filterrequestId}${filtername}${filterlastname}${filteremail}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsed = handleFetch({
            url: `bo/users_plans_review?${filterstype}${filterrequestId}${filtername}${filterlastname}${filteremail}${filterdocument}&$limit=50000`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsed
            .then((r) => {
                setUsersFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filterrequestId = dataFilter.requestId !== '' ? `&requestId=` + dataFilter.requestId : ''
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filterstype = dataFilter.errorType !== '' ? "&errorType[$eq]=" + dataFilter.errorType : ''
        const response = handleFetch({
            url: `bo/users_plans_review?${filterstype}${filterrequestId}${filtername}${filterlastname}${filteremail}${filterdocument}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Facturación de planes en revisión</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/facturacioncursos'} className={`${pathname == '/admin/facturacioncursos' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/facturacion'} className={`${pathname == '/admin/facturacion' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Planes</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/facturacioncursosreviews'} className={`${pathname == '/admin/facturacioncursosreviews' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Cursos en revisión</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/facturacionreviews'} className={`${pathname == '/admin/facturacionreviews' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Planes en revisión</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listreports.csv"}
                            onSubmit={handleSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='number' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Correo</span>
                                <div>
                                    <input type='text' name='email' value={dataFilter?.email || ''} onChange={handleChangeFilter} placeholder="Correo electrónico" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Código de operación</span>
                                <div>
                                    <input type='text' name='requestId' value={dataFilter?.requestId || ''} onChange={handleChangeFilter} placeholder="Código de operación" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Estado</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='errorType'
                                        value={dataFilter?.errorType || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="0">Rechazada</option>
                                        <option value="1">Pendiente</option>
                                    </select>
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable users={users} total={total} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar reportes
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ users, total, dataFilter }) => {

    const [page, setPage] = useState(0);
    // Header para listado de reportes
    const headers = [
        {
            "name": "NOMBRE COMPLETO",
            "sort_name": "name",
            "width": "20%"
        },
        {
            "name": "CORREO ELECTRÓNICO",
            "sort_name": "email",
            "width": "20%"
        },
        {
            "name": "PLAN ADQUIRIDO",
            "sort_name": "plan",
            "width": "18%"
        },
        {
            "name": "FECHA PAGO",
            "sort_name": "datepayment",
            "width": "12%"
        },
        {
            "name": "CODIGO OP.",
            "sort_name": "code",
            "width": "10%"
        },
        {
            "name": "ESTADO",
            "sort_name": "status",
            "width": "12%"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))
    const types = ['Rechazada', 'Pendiente'];
    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(total)
    }, [users, total])

    const resetState = () => {
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(users.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filterrequestId = dataFilter.requestId !== '' ? `&requestId=` + dataFilter.requestId : ''
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filterstype = dataFilter.errorType !== '' ? "&errorType[$eq]=" + dataFilter.errorType : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/users_plans_review?${filterstype}${filterrequestId}${filtername}${filterlastname}${filteremail}${filterdocument}&$skip=${result}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "5%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        usersSorted.length != 0 ?
                            usersSorted.map((user, index) => {
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user?.['webuser.name']} {user?.['webuser.lastname']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}><div className="UserAdmin__table-td">{user?.['webuser.email']}</div></td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user['plan.title']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{new Date(user.createdAt).toISOString().split('T')[0]}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user.requestId}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{types[user?.errorType]}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={`/admin/facturacion/detalle`} state={{users: user}}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="7" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}