import React from 'react'
import { SimpleFooter } from '../../footer/Footer'
import { AdminHeader } from '../plans/AdminHeader'
import { B2BUserSection } from "./B2BUserSection";
import { useLocation } from 'react-router-dom';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección usuarios
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const B2BUserPage = () => {
  
  const location = useLocation();
  const dataB2B = location?.state?.dataB2B ? location.state.dataB2B : '';
  return (
    <>
      <AdminHeader />
      <div style={{marginTop: "100px"}}></div>
      <B2BUserSection dataB2B={dataB2B} />
      <SimpleFooter></SimpleFooter>
    </>
  )
}
