import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para manejar formato de montos en precios
 * @param {string} value Valor del monto a mostrar
 * @param {string} currency Muestra el formato de moneda
 * @param {string} name Nombre de la variable
 * @param {string} label Etiqueta de la variable
 * @param {function} onChange Función para cambio en el input
 * @param {string} inputClasses Clase de estilo para el formato
 * @param {boolean} allowNegative Habilita o deshabilita aceptar valores negativos
 * @param {boolean} disabled Habilita o deshabilita el input
 * @function Filters
 */
export const AmountFormat = ({
                                 value, percentage, currency, displayType, name, label,
                                 onChange, onBlur, controlClasses, inputClasses, fieldClasses,
                                 allowNegative, disabled,
                             }) => {
    
    const numberStr = value ? value.toString() : '';
    const check1 = numberStr.indexOf('.');
    const check2 = numberStr.indexOf(',');
    let number = value;
    if (check1 > check2) {
        number = numberStr.replace(/[,a-zA-Z $]/gi, '').replace('.', ',');
    }
    return (
        <NumberFormat
            thousandSeparator='.'
            decimalSeparator=','
            //customInput={TextInput}
            displayType={displayType}
            prefix={`${currency?currency:' '} `}
            suffix={`${percentage?' %':''}`}
            value={number}
            decimalScale={2}
            allowNegative={allowNegative}
            fixedDecimalScale
            name={name}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            className={controlClasses}
            controlclasses={controlClasses}
            inputclasses={inputClasses}
            fieldclasses={fieldClasses}
            disabled={disabled}
        />
    );
}