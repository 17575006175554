import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { apiConfig } from '../../../api/apiConfig';
import { GooglePieChart } from '../charts/PieGoogleCharts';
import { BarGoogleCharts } from '../charts/BarGoogleCharts';
import { LineGoogleCharts } from '../charts/LineGoogleCharts';
import { GoogleDonutChart } from '../charts/DonutGoogleCharts';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2024<br>
 * Dashboard sección administrativa
 * @interface DashboardAdminSection
 */
export const DashboardAdminSection = () => {
    const [companies, setCompanies] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [registers, setRegisters] = useState([]);
    const [totalUsersbyType, setTotalUsersbyType] = useState([]);
    const [totalPlans, setTotalPlans] = useState(0);
    const [totalCourses, setTotalCourses] = useState(0);
    const [completedCoursesTitles, setCompletedCoursesTitles] = useState([]);
    const [popularCourses, setPopularCourses] = useState([]);
    const [data, handleFetch] = useFetch();
    const [dataFilter, setDataFilter] = useState({
        companyId: '',
        type: '',
        until: '',
        since: '',
    })
    const location = useLocation();
    const { pathname } = location;
    const viewCompanies = () => {
        const responsesegment = handleFetch({
            url:`bo/companiesb2b`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responsesegment
            .then((r) => {
                setCompanies(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshData = () => {        
        let filtertype = dataFilter.type !== '' ? "&segment=" + dataFilter.type : ''
        let filterendDate = dataFilter.until !== '' ? "&endDate=" + dataFilter.until : ''
        let filterstartDate  = dataFilter.since !== '' ? "&startDate=" + dataFilter.since : ''
        let filtercompany = dataFilter.companyId !== '' ? "&company=" + dataFilter.companyId : "&company=" +apiConfig.company
        const responsec = handleFetch({
            url: `bo/datadashboard?${filtercompany}${filtertype}${filterstartDate}${filterendDate}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
            responsec.then(async (r) => {
                let newDataTitles = r?.topCoursesMostEnrolled.map(item => ({
                    ...item,
                    value: parseInt(item.value, 10)
                }));
                setCompletedCoursesTitles(newDataTitles)
                let newDataCourses = r?.topCoursesCompleted.map(item => ({
                    ...item,
                    value: parseInt(item.value, 10)
                }));
                setPopularCourses(newDataCourses)
                setRegisters(r?.usersRegisters)
                setTotalUsersbyType(r?.totalUsersbyType)
                setTotalUsers(r?.totalUsers)
                setTotalCourses(r?.totalCourses)
                setTotalPlans(r?.totalPlans)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        window.history.replaceState(null, '');
        viewCompanies();
        refreshData();
    }, [totalPlans])

    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const onSubmit = async (e) => {
        e.preventDefault();
        refreshData();
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    const fecha = new Date();
    const diaSemana = fecha.toLocaleDateString(undefined, { weekday: 'long' });
    const dia = fecha.getDate();
    const mes = fecha.toLocaleDateString(undefined, { month: 'long' });
    const horaAmPm = fecha.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true });
    const [hora, amPm] = horaAmPm.split(' ');
    return (
        <>
            <div className='row justify-content-center mt-4 mb-4'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Dashboard</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/dashboard'} className={`${pathname == '/admin/dashboard' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Gráficas</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/dashboardreport'} className={`${pathname == '/admin/dashboardreport' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Reportes</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-end ">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                  <div className="col-4 d-flex align-items-center bg-green justify-content-center rounded-left">
                    <div className="text-center">
                      <div
                        format="MMMM"
                        className="overflowm text-uppercase text-sm"
                        style={{display: "inline-block"}}
                      >
                        {mes}
                      </div>
                      <br />
                      <div
                        format="D"
                        className="text-uppercase h2report mt0"
                        style={{display: "inline-block"}}
                      >
                        {dia}
                      </div>
                    </div>
                  </div>
                  <div className="col-8 p-3 rounded-right">
                    <div
                      format="dddd"
                      className="text-uppercase"
                      style={{display: "inline-block"}}
                    >
                      {diaSemana}
                    </div>
                    <br />
                    <div
                      format="h:mm"
                      className="h2hour mt0 mr-sm"
                      style={{display: "inline-block"}}
                    >
                      {hora}
                    </div>
                    <div
                      className="text-muted text-sm"
                      style={{position: "absolute", bottom: "5%"}}
                    >
                      {amPm}
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                        <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
                            <i className="icons fa-solid fa-user"></i>
                        </div>
                        <div className="col-8 p-3 bg-primary rounded-right">
                            <div className="h2report mt-0">{totalUsers} Usuarios</div>
                            <div className="overflowr text-uppercase">Usuarios Registrados</div>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                        <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left">
                            <i className="icons fa-solid fa-notes-medical"></i>
                        </div>
                        <div className="col-8 p-3 bg-purple rounded-right">
                            <div className="h2report mt-0">{totalPlans}</div>
                            <div className="overflowr text-uppercase">Planes agregados</div>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                        <div className="col-4 d-flex align-items-center bg-green-dark justify-content-center rounded-left">
                            <i className="icons fa-solid fa-file-contract"></i>
                        </div>
                        <div className="col-8 p-3 bg-green rounded-right">
                            <div className="h2report mt-0">{totalCourses} Cursos</div>
                            <div className="overflowr text-uppercase">Cursos Disponibles</div>
                        </div>
                        </div>
                    </div>
                </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            onSubmit={onSubmit}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Inicio</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={dataFilter?.until || newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Fin</span>
                                <div>
                                    <input type="date" name="until" value={dataFilter?.until || ''} onChange={handleChangeFilter} min={dataFilter?.since} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Compañia B2B</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='companyId'
                                        value={dataFilter?.companyId || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="">Todos</option>
                                        { companies.length !== 0 && companies.map((companie) => {
                                                    return (<option key={companie.id} value={companie.id}>{companie.name}</option>)
                                                })}
                                    </select>
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                        <>
                                        <div className='row text-center justify-content-center'>
                                            <div className='col-12 col-lg-6 mt-4 mb-4'>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mt-4 mb-2 fw-bold'>
                                                        USUARIOS REGISTRADOS
                                                    </div>
                                                </div>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mb-4'>
                                                        <LineGoogleCharts data={registers} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-6 mt-4 mb-4'>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mt-4 mb-2 fw-bold'>
                                                        USUARIOS POR B2B
                                                    </div>
                                                </div>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mb-4'>
                                                        <BarGoogleCharts data={totalUsersbyType}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row text-center justify-content-center'>
                                            <div className='col-12 col-lg-6 mt-4 mb-4'>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mt-4 mb-2 fw-bold'>
                                                        CURSOS MAS VISTOS
                                                    </div>
                                                </div>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mb-4'>
                                                        <GooglePieChart data={completedCoursesTitles.slice(0, 5)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-6 mt-4 mb-4'>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mt-4 mb-2 fw-bold'>
                                                        CURSOS FINALIZADOS
                                                    </div>
                                                </div>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mb-4'>
                                                        <GoogleDonutChart data={popularCourses.slice(0, 5)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}