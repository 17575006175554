import React, { useEffect } from 'react'
import { Footer } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { ContactUsSection } from './ContactUsSection';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para formulario de contacto
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const ContactUsPage = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
    <div>
        <LandingHeader />
        <div style={{marginTop: "70px"}}></div>
        <ContactUsSection />
        <Footer />
    </div>
  )
}
