import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Footer } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { CatalogSection } from './CatalogSection'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección catálogo
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const CatalogPage = () => {
    const location = useLocation();


    const [searchCriteria, setSearchCriteria] =  useState(location.state?location.state:{});
    const searchAction = (keyword) => {
      setSearchCriteria({keyword});
    }

    return (
    <div>
        <LandingHeader  searchAction={searchAction} />
        <div style={{marginTop: "75px"}}></div>
        <CatalogSection filter={searchCriteria} />
        <Footer />
    </div>
  )
}
