import React from 'react';
import { AdminHeader } from './AdminHeader';
import { SimpleFooter } from '../../footer/Footer';
import { PlanCreateEditSection } from './PlanCreateEditSection';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Interface para editar contenido de sección planes
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface PlanCreateEditPage
 */
export const PlanCreateEditPage = () => {
   return (
        <>
            <AdminHeader />
            <div style={{marginTop: "80px"}}></div>
            <PlanCreateEditSection />
            <SimpleFooter />
        </>
   )
}