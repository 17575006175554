import React from 'react'
import { Link } from 'react-router-dom'
import { AuthFormContainerPage } from '../controls/UIControls'

const landingImages = require.context('../../assets/images', true)

export const ValidatingPurchasePage = () => {

    const properties = {
        title: "Estamos validando tu compra, por favor espera unos minutos.",
        header: (<div>
            <img
                alt='Ean logo'
                src={landingImages('./loading.png')}
                style={{width: "100px", height: "auto"}}
                className="mx-auto d-block auth__img-check mb-3"
            />
        </div>),
        content: (<Link to="/" replace >
                <button className="auth__btn-form">Volver a cargar</button>
            </Link>
        )
    }
    return <AuthFormContainerPage {...properties}></AuthFormContainerPage>
}