import React, { useState } from 'react'
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../../styles/components/verifyPage/verify.scss';
import { useAuthToken } from '../../hooks/useAuthToken';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para código de verificación
 * @function CodeKey
 */
const CodeKey = props => {
    return (
        <input 
            type='text' 
            name={ props.name }
            maxLength="1"
            minLength="1"
            className='form-control auth__input-code'
            value={ props.value }
            onChange={ props.onChange }
            />
            );
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de verificación de correo electrónico en proceso de registro
 * @param {props} props Contenido para mensaje de error (className, message)
 * @function VerifyEmailPage
 */
export const VerifyEmailPage = (props) => {
    const [data, handleFetch] = useFetch();
    const {authToken}= useAuthToken();

    const [fetchResult, setFetchResult] = useState({error:0,message:''});
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [ formValues, handleInputChange ] = useForm({
        code_1: '',
        code_2: '',
        code_3: '',
        code_4: '',
        code_5: '',
        code_6: ''
    });
    const navigator = useNavigate();
    const location = useLocation();
    
    const GENERIC_ERROR_MESSAGE='Ocurrió un error al realizar la operación, por favor intenta nuevamente';
    const GENERIC_ERROR_CODE=1000;

    const genericError = {
        error:GENERIC_ERROR_CODE,
        message:GENERIC_ERROR_MESSAGE,
    }
    const navigate = useNavigate();

    /*const handleCloseModal = () => {
        setShowModalUpdated(false);
        navigator('/perfil');
    }*/
    // Handle para manejo de inputs de código de verificación
    const handleCodeChange = e=>{
        const target = {target: {name:e.target.name,value:e.target.value}};
        if("" !== e.target.value){

            const [, fieldIndex] = e.target.name.split("_");
            const nextSibling = document.querySelector(`input[name=code_${parseInt(fieldIndex,10)+1}]`);
            if (nextSibling !==null){
                nextSibling.focus();
            }
        }
        handleInputChange(target);
    }

    const { code_1, code_2, code_3, code_4, code_5, code_6 } = formValues;
    // Función para mostrar mensaje de error de verificación
    const handleError = fetchErrorResult => {
        if(fetchErrorResult.errors.length>0) {
            const e = fetchErrorResult.errors[0];
            setFetchResult({error:e.id,message:e.message});
        } else {
            setFetchResult(genericError);
        }
    }

    const clearError = (message='') => {
        setFetchResult({error:message==''?0:-1,message});
    }
    // Handle de verificación de correo electrónico
    const HandleVerify = (e) => {
        e.preventDefault();
        const code = code_1 + code_2 + code_3 + code_4 + code_5 + code_6;

        if(location.state.changeEmail){
            const response = handleFetch({
                url:'users/verifyupdate',
                method: 'POST', 
                data: {
                    email: location.state.email,
                    lastname:location.state.lastname,
                    name:location.state.name,
                    phone: location.state.phone, 
                    code: code
                },
                headers: {Authorization: `Bearer ${authToken}`} 
            });
            response
            .then(r => {
                    navigate('/perfil',{state: {
                            toastProperties:{
                                show:true,
                                type:'success',
                                title: 'Tu correo se ha actualizado correctamente',
                                message: 'Recuerda que ahora usarás '+location.state.email+' para acceder a EanX',
                                position: {horizontal:'left'},
                            }
                        }
                    });
            },
            error => {
                handleError(error);
            });

        }else{
            handleFetch({
                url:'users/verify',
                method: 'POST', 
                data: {
                    email: location.state.email,
                    code: code
                }
            })
            .then(r => {
                const state = Object.assign({},{user:r},location.state);
                clearError();
                if(location.state?.plan?.price != 0 || location.state?.course?.price){
                    navigator('/tipo-de-pago', {state}) ;
                }else{
                    navigator('/cuenta-creada', {state}) ;
                }
            })
            .catch( error => {
                    handleError(error);
                }
            );
        }
    };
    // Función reenvío de código de confirmación
    const handleResendCode = (e) => {
        e.preventDefault();
        // Si el proceso es para cambio de correo elecrónico, se envían nombre, apellido y teléfono
        if(location.state.changeEmail){
            const response = handleFetch({
                url:'users/update',
                method: 'POST', 
                data: {
                    email: location.state.email,
                    lastname:location.state.lastname,
                    name:location.state.name,
                    phone: location.state.phone,
                },
                headers: {Authorization: `Bearer ${authToken}`} 
    
            });
        }else{
            // Si el proceso NO es para cambio de correo elecrónico, solo se envía como data el correo (Depredaco)
            // Ahora se hace el envío vía SMS
            const response = handleFetch({
                url:'users/sendsms',
                method: 'POST', 
                data: {
                    email: location.state.email
                }
            });
            response
            .then(r => {
                clearError('Un nuevo código fue reenviado exitosamente a su teléfono');
            },
            error => {
                handleError(error);
            });
        }
    };

    const inputCodes = [];

    for (const key in formValues) {
        if (Object.hasOwnProperty.call(formValues, key)) {
            inputCodes.push(<CodeKey name={key} key={key} value={formValues[key]} onChange={handleCodeChange}></CodeKey>);
        }
    }

    return (
        <div className="auth__main min-vh-100 d-flex align-items-center">
            <div className="auth__box-container auth__box-container--rounded-5">
            <div className=''>
                <img 
                    alt='Ean logo' 
                    src={landingImages('./ean-logo-sin 1.png')} 
                    style={{width: "100px", height: "auto"}} 
                    className="rounded mx-auto d-block"
                />
                </div>
                <h3 className="auth__title">Verificación de correo</h3>
                {location.state.registerPay?
                    <>
                        <div className='auth__numbers-container'>
                            
                            <div className='auth__number-passed' >
                                1
                            </div>
                            <hr className='auth__numbers-line-active'/>
                            <hr className='auth__numbers-line-active'/>
                            <div className='auth__number-passed'>
                                2
                            </div>
                            <hr className='auth__numbers-line-active'/>
                            <hr className='auth__numbers-line-blocked'/>
                            
                            <div className='auth__number-blocked'>
                                3
                            </div>
                        </div>
                        <div className='auth__numbers-text-container mb-3'>
                            <div className='auth__number-text-active'>
                                Información
                            </div>
                            <div className='auth__number-text-active'>
                                Validación de correo
                            </div>
                            <div className='auth__number-text-blocked'>
                                Confirmación
                            </div>
                        </div>
                    </>
                    :
                    null    
                }
                <form onSubmit={ HandleVerify }>
                <label className='auth__form-label' style={{fontSize: "16px"}}>Te hemos enviado un correo con un código de verificación</label>
                <label className='auth__form-label' style={{fontSize: "16px"}}><i>El proceso puede tomar hasta 2 minutos</i></label>
                <label className='auth__form-label' style={{fontSize: "16px"}}>
                    Código de verificación 
                    <span className='auth__required-input'>*</span></label>
                <div className="auth__codes d-flex justify-content-center my-3">
                    { inputCodes }
                </div>
                <div>¿No has recibido el correo? <a href="#" className="auth__link" onClick={handleResendCode}>Reenviar código por SMS</a></div>
                <div className='mt-3'>Si aún no llega tu código, por favor <Link to="/contactanos" className="auth__link">Contáctanos</Link></div>
                <button
                    type="submit"
                    className={`auth__btn-form ${data.loading?'auth__btn-form--disabled':''}`}
                    disabled={data.loading}
                >
                {!data.loading && (<span>Confirmar</span>)}
                {data.loading && (<><span> Procesando... </span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) }
                </button>
                {
                    (fetchResult.error!==0) && (<div className={`message__text--${(fetchResult.error>0)?'error':'ok'}`}>{fetchResult.message}</div>)
                }
                </form>
            </div>
        </div>
    )
}