import React, { useEffect, useState } from 'react';
import { Dialog, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useCourseTaker } from '../../hooks/useCourseTaker';
import { useFetch } from '../../hooks/useFetch';
import { useUserPlan } from '../../hooks/useUserPlan';
import { Toast } from '../controls/UIControls';
import Modal from '@mui/material/Modal';
import { arrayPaginated, MINIMUM_CERT_SCORE, PAGE_SIZE, TakeCourseIndicators } from './shared';

const EAN_LINKEDIN_ID = 58745;
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función compartir certificado
 * @param {boolean} enabled Variable para habilitar botones
 * @param {string} link Enlace a 
 * @param {string} icon Ícono del red social a compartir
 * @function ShareButton
 */
const ShareButton = ({enabled,link,icon}) => {
    const iconButton = <i 
                            className={`fa-brands fa-${icon} fa-2xl  ProfilePage__share-buttons ProfilePage__share-buttons--${icon} ${enabled?'':'ProfilePage__share-buttons--disabled'}`} >
                        </i>
    
    return enabled?(<a href={link} target='_blank'>{iconButton}</a>):iconButton;
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para descargar certificado
 * @param {string} course Datos del curso
 * @param {string} buttonLabel Nombre del nombre a mostrar
 * @param {event} actionHandler Ícono del red social a compartir
 * @param {boolean} waiting Variable para validación de carga de 
 * @param {boolean} disabled Variable para inhabilitar botón de curso
 * @function CourseActionButton
 */
const CourseActionButton = ({course, buttonLabel, actionHandler, waiting, disabled}) => {
    if(waiting) disabled = true;
    
    const clickHandler = (waiting || disabled)?(null):(e=>actionHandler(course));
    
    return (
        <div className={`col-12 ProfilePage__download-certificate-button ${disabled?'ProfilePage__download-certificate-button--disabled':''}`} 
                onClick={clickHandler}>
                <span>{buttonLabel}</span>
                {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}
        </div>
    );
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para copiar enlace de certificado a portapapeles
 * @param {string} link Datos del curso
 * @param {string} course Datos del curso
 * @function CopyLinkToClipboard
 */
const CopyLinkToClipboard = ({link, course}) => {
    const enabled = !!link;
    const [showCopyToast, setShowCopyToast] = useState({show: false, message:''});
    const toastReset = () => {
        setShowCopyToast({show:false});
    }

    const  copyTextToClipboard = (text) => {
        if ('clipboard' in navigator) {
          return navigator.clipboard.writeText(text);
        } else {
          return new Promise((resolve, reject) => document.execCommand('copy', true, text)?resolve():reject());
        }
      }
      // Función copiar link
      const  copyLink = (text) => {
        copyTextToClipboard(text)
        .then (()=>setShowCopyToast({show: true, message:(<span>El enlace al certificado del curso <strong>{course.name}</strong> fue copiado exitosamente.</span>), type:'success'}))
        .catch(()=>setShowCopyToast({show: true, message:'Ocurrió un error al copiar el enlace.', type:'error'}));
      }

      return (
        <>
        <div className={`col-12 ProfilePage__copy-link-button ${enabled?'':'ProfilePage__copy-link-button--disabled'}`} onClick={enabled?(() => copyLink(link)):null}>
            <i className="fa-solid fa-copy"></i><span className='ms-2'>Copiar enlace</span>
        </div>
            <Toast 
                show={showCopyToast.show}
                message={showCopyToast.message}
                type={showCopyToast.type}
                closeCallback={toastReset}
            >
            </Toast>
        </>
  )
}

// Función finalizado curso
export const FinishedCourses = ({myCourses, user}) =>{
    const coursesFiltered = myCourses.filter((e)=>e['user_study.progress']===100 && e['user_study.avgScore']>=MINIMUM_CERT_SCORE);
    const numberOfPages = Math.ceil(coursesFiltered.length/PAGE_SIZE);

    const [fetchData,handleFetch,handleFetchPDF] = useFetch();
    const {authToken, getSessionUser} = useAuthToken();
    const {checkUserPlan} =  useUserPlan();
    const currUser = getSessionUser();
    const [coursesDisplayed, setCoursesDisplayed] = useState(arrayPaginated(coursesFiltered, 1));
    const [showToast, setShowToast] = useState({show:false, message:''});
    const [userData, setUserData] = useState({
        userId: user?.id,
        courseId: '',
        SimpleRegister: null,
        ExperiencePlatform: null,
        FulfillmentExpectations: null,
        FulfillmentComments: '',
        Recommendation: null,
        Comments: '',
      });
    const [checkPlan, setCheckPlan] = useState(null);
    const [surveyCourse, setSurveyCourse] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [showModalSurvey, setShowModalSurvey] = useState(false);
    const [nameCourseSurvey, setNameCourseSurvey] = useState('');
    const [errorDialog, setErrorDialog] = useState(false);
    const [data,courseTaker] = useCourseTaker();
    
    const onCourseRedirection = (course) => {
        courseTaker(course, () => setErrorDialog(true) );
    }
    // Función para traducir mes a español
    const getSpanishDate = () => {
        const spanishMonths = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 
                                'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const now = new Date();
        return now.getDate()+ " de " + spanishMonths[now.getMonth()] + " de " + now.getFullYear();
    }
    // Función para generar certificado
    const generateCertificate = (course) => {
        let certificationData = Object.assign({},coursesCertificationData);
        const key =  course.contentProviderId+'_'+course.courseId+'_'+course['attr.type'];

        certificationData[key].buttonProps = {
            buttonLabel: "Generando Certificado...",
            waiting:true
        }
       setCoursesCertificationData(certificationData);
        // Handle para llamar endpoint de certificados
        handleFetch({
            url: 'certifications',
            method: 'POST', 
            headers: {Authorization: `Bearer ${authToken}`},
            data: {
                certCode: course['attr.certificationTemplateId'],
                courseName: course.name,
                certDate: getSpanishDate(),
                receiver: {
                    fullname: `${currUser.name} ${currUser.lastname}`,
                    document: currUser.document,
                    email: currUser.email
                }
            }
            }).then(cert => {
                return new Promise((resolve, reject) => {
                    handleFetch({
                        url: `certifications/${cert.certificateCode}`,
                        method: 'GET', 
                        headers: {Authorization: `Bearer ${authToken}`},
                    })
                    .then(certData => resolve({certId:cert.certificateCode, certUrls: certData.data}))
                    .catch(error => reject(error));
                })
            }).then(certData => {
                return handleFetch ({
                    url: `certifications/user_certifications`,
                    method: 'POST', 
                    headers: {Authorization: `Bearer ${authToken}`},
                    data: {
                        userStudyId: course['user_study.id'],
                        certificationId: certData.certId, 
                        certificationUrl: certData.certUrls.urlPdfCertificate,
                        certificationViewUrl: certData.certUrls.urlPublicViewCertificate,
                        certificationShareUrl:certData.certUrls.urlShareSocialNetwork,
                        certificationShareLinkedInUrl:`${certData.certUrls.urlShareLinkedinProfile}&organizationId=${EAN_LINKEDIN_ID}`,
                        certificationShareWhatsappUrl:certData.certUrls.urlShareSocialNetworkOnlyWhatsapp,
                        type: 1
                    }
                });
            }).then (userCertification => {
                certificationData = Object.assign({},coursesCertificationData);
                certificationData[key].buttonProps = {
                    buttonLabel: "Descargar Certificado",
                    waiting:false,
                    actionHandler: downloadCertificate,
                };
                certificationData[key].certificateUrls = {
                    certificationUrl:userCertification.certificationUrl,
                    certificationViewUrl:userCertification.certificationViewUrl,
                    certificationShareUrl:userCertification.certificationShareUrl,
                    certificationShareLinkedInUrl:userCertification.certificationShareLinkedInUrl,
                    certificationShareWhatsappUrl:userCertification.certificationShareWhatsappUrl,
                    type: 1
                };
                certificationData[key].certificateData = {
                    certificationId:userCertification.certificationId,
                    certificationIssueDate:new Date(),
                };
                setShowToast({show:true, type:'success', message: `<span>Tu certificado para el curso <strong>${course.name}</strong> se generó exitosamente. Ya puedes descargarlo.</span>`});
                setCoursesCertificationData(certificationData);
            }).catch(()=> {
                certificationData[key].buttonProps = {
                    buttonLabel: "Generar Certificado",
                    wait:false, 
                    actionHandler: generateCertificate
                }
                setCoursesCertificationData(certificationData);
                setShowToast({show:true, type:'error', message: `<span>Ocurrió un error generando certificadopara el curso <strong>${course.name}</strong>. Intenta nuevamente.</span>`});
            })  
    }
    // Función para generar certificado
    const generateBadge = (course) => {
        let certificationData = Object.assign({},coursesCertificationData);
        const key =  course.contentProviderId+'_'+course.courseId+'_'+course['attr.type'];

        certificationData[key].buttonProps = {
            buttonLabel: "Generando Insignia...",
            waiting:true
        }
       setCoursesCertificationData(certificationData);
        // Handle para llamar endpoint de certificados
        handleFetch({
            url: 'certifications/badges',
            method: 'POST', 
            headers: {Authorization: `Bearer ${authToken}`},
            data: {
                badgeCode: course['attr.certificationTemplateId'],
                courseName: course.name,
                certDate: getSpanishDate(),
                receiver: {
                    name: currUser.name,
                    lastname: currUser.lastname,
                    documentType: currUser.documentType,
                    document: currUser.document,
                    email: currUser.email
                }
            }
            }).then(cert => {
                return new Promise((resolve, reject) => {
                    handleFetch({
                        url: `certifications/badges/${cert.badgeCode}`,
                        method: 'GET', 
                        headers: {Authorization: `Bearer ${authToken}`},
                    })
                    .then(certData => resolve({certId:cert.badgeCode, certUrls: certData.data}))
                    .catch(error => reject(error));
                })
            }).then(certData => {
                return handleFetch ({
                    url: `certifications/user_badges`,
                    method: 'POST', 
                    headers: {Authorization: `Bearer ${authToken}`},
                    data: {
                        userStudyId: course['user_study.id'],
                        certificationId: certData.certId, 
                        certificationUrl: certData.certUrls.urlImageBadge,
                        certificationViewUrl: certData.certUrls.urlPublicViewBadge,
                        certificationShareUrl:certData.certUrls.urlShareSocialNetwork,
                        certificationShareLinkedInUrl:`${certData.certUrls.urlShareLinkedinProfile}&organizationId=${EAN_LINKEDIN_ID}`,
                        certificationShareWhatsappUrl:certData.certUrls.urlShareSocialNetworkOnlyWhatsapp,
                        type: 2
                    }
                });
            }).then (userCertification => {
                certificationData = Object.assign({},coursesCertificationData);
                certificationData[key].buttonProps = {
                    buttonLabel: "Descargar Insignia",
                    waiting:false,
                    actionHandler: downloadCertificate,
                };
                certificationData[key].certificateUrls = {
                    certificationUrl:userCertification.certificationUrl,
                    certificationViewUrl:userCertification.certificationViewUrl,
                    certificationShareUrl:userCertification.certificationShareUrl,
                    certificationShareLinkedInUrl:userCertification.certificationShareLinkedInUrl,
                    certificationShareWhatsappUrl:userCertification.certificationShareWhatsappUrl,
                };
                certificationData[key].certificateData = {
                    certificationId:userCertification.certificationId,
                    certificationIssueDate:new Date(),
                };
                setShowToast({show:true, type:'success', message: `<span>Tu Insignia para el curso <strong>${course.name}</strong> se generó exitosamente. Ya puedes descargarlo.</span>`});
                setCoursesCertificationData(certificationData);
            }).catch(()=> {
                certificationData[key].buttonProps = {
                    buttonLabel: "Generar Insignia",
                    wait:false, 
                    actionHandler: generateBadge
                }
                setCoursesCertificationData(certificationData);
                setShowToast({show:true, type:'error', message: `<span>Ocurrió un error generando la Insignia para el curso <strong>${course.name}</strong>. Intenta nuevamente.</span>`});
            })  
    }

    const [showUnavailableDialog, setShowUnavailableDialog] = useState({show:false});
    const [showNoPlanDialog, setShowNoPlanDialog] = useState({show:false});
    // Función para mostrar aviso de certificado no disponible
    const unavailableCertificate = (course) => {
        setShowUnavailableDialog({show: true, courseName: course.name});
    }

    const closeUnavailbleDialog = () => {
        setShowUnavailableDialog({show: false});
    }

    // Función mostrar mensaje plan no tiene certificado
    const noPlanCertificate = (course) => {
        setShowNoPlanDialog({show: true, courseName: course.name});
    }

    const closeNoPlanDialog = () => {
        setShowNoPlanDialog({show: false});
    }
    // Función descargar certificado
    const downloadCertificate = (course) => {
        const key = course.contentProviderId+'_'+course.courseId+'_'+course['attr.type'];
        window.open(coursesCertificationData[key].certificateUrls.certificationUrl, '_blank');
    }
    // Función descargar certificado gratuito
    const downloadFreeCertificate = async (course) => {
        try {
            await handleFetch({
                url: `courses/surveys?userId[$eq]=${currUser?.id}&course=${course?.courseId}`,
                method: 'GET', 
                headers: {Authorization: `Bearer ${authToken}`},
                }).then(async cert => {
                    if(cert?.data && cert?.data.length!==0){
                        try {
                            const blob = await handleFetchPDF({
                                url: 'certifications/gencertificates/download',
                                method: 'POST',
                                data: {
                                    "certCode": "XewdiQbAtoPT",
                                    "courseName": course?.title,
                                    "userId": currUser.id,
                                    "studyId": course?.courseId,
                                    "certDate": getSpanishDate(),
                                    "receiver": {
                                        "fullname": `${currUser?.name} ${currUser?.lastname}`,
                                        "document": currUser?.document,
                                        "email": currUser?.email
                                    }},
                                responseType: 'blob'
                            });
                    
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = 'certificado.pdf';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                            window.URL.revokeObjectURL(url);
                        } catch (error) {
                            console.error('There was an error downloading the PDF:', error);
                        }
                    }else{
                        modalSurvey(course)
                    }
                }).catch(()=> {
                    //setShowToast({show:true, type:'error', message: `<span>Ocurrió un error llenando la encuesta para el curso <strong>${surveyCourse.name}</strong>. Intenta nuevamente.</span>`});
                })  
            } catch (error) {
                console.error('There was an error downloading the PDF:', error);
            }
        
    }
    // Función modal para contestar encuesta
    const modalSurvey = async (course) => {
        setShowModalSurvey(true);        
        setSurveyCourse(course)
        setNameCourseSurvey(course.name);
        setUserData({
         ...userData,
         "courseId": course.courseId,
       });
    }
    // Función generar certificado
    const getCertificationData = (courses) => {
        let certificationData = {};
        courses.map(c => {
            const key = c.contentProviderId+'_'+c.courseId+'_'+c['attr.type'];

            certificationData[key]= {};
            certificationData[key].buttonProps = 
            (checkPlan && checkPlan?.planPrice!==0 && c['user_study.users_studies_certification.certificationId']==null)?
            {
                buttonLabel: c['attr.type']==1?'Generar Certificado':'Generar Insignia', 
                wait:false, 
                actionHandler:!!c['attr.certificationTemplateId']?modalSurvey:unavailableCertificate
            }
            :
            {
                buttonLabel: c['user_study.users_studies_certification.certificationId']==1||c['attr.type']==1?'Descargar Certificado':'Descargar Insignia', 
                wait:false, 
                actionHandler: downloadFreeCertificate,
            };
            certificationData[key].certificateUrls = {
                certificationUrl:c['user_study.users_studies_certification.certificationUrl'],
                certificationViewUrl:c['user_study.users_studies_certification.certificationViewUrl'],
                certificationShareUrl:c['user_study.users_studies_certification.certificationShareUrl'],
                certificationShareLinkedInUrl:c['user_study.users_studies_certification.certificationShareLinked'],
                certificationShareWhatsappUrl:c['user_study.users_studies_certification.certificationShareWhatsa'],
            };
            certificationData[key].certificateData = {
                certificationId:c['user_study.users_studies_certification.certificationId'],
                certificationIssueDate:c['user_study.users_studies_certification.certificationIssueDate'],
            }
        });
        return certificationData;
    }

    const [coursesCertificationData, setCoursesCertificationData] = useState(getCertificationData(coursesFiltered));
    const handleChangePage = (event, page) =>{
        setCoursesDisplayed(arrayPaginated(coursesFiltered, page));
        window.scrollTo(0,0);
    }

    const toastReset = () => {
        setShowToast({show:false});
    }

    const changeNoPlanHandler = (courses) => {
        let certificationData = Object.assign({},coursesCertificationData);
        courses.map(c => {
            const key = c.contentProviderId+'_'+c.courseId+'_'+c['attr.type'];
            if(c['user_study.users_studies_certification.certificationId']==null) {
                certificationData[key].buttonProps.actionHandler = noPlanCertificate; 
            }
        });
        return certificationData;
    }

    const [certificationNotAllowed, setCertificationNotAllowed] = useState(false);

    const changeNoCertificationsLeft = (courses) => {
        let certificationData = Object.assign({},coursesCertificationData);
        courses.map(c => {
            const key = c.contentProviderId+'_'+c.courseId+'_'+c['attr.type'];
            if(c['user_study.users_studies_certification.certificationId']==null) {
                certificationData[key].buttonProps.disabled = true; 
            }
        })
        return certificationData;

    }

    useEffect(()=> {
        checkUserPlan(authToken).
        then(hasPlan => {
            setCheckPlan(hasPlan)
            if(!hasPlan || (typeof hasPlan == 'string' && !JSON.parse(hasPlan))) {
                const certificationData = changeNoPlanHandler (coursesFiltered);         
                setCoursesCertificationData(certificationData);
            } else if (!hasPlan.canCertificate) {
                setCertificationNotAllowed(true);
                const certificationData = changeNoCertificationsLeft (coursesFiltered);         
                setCoursesCertificationData(certificationData);
            }
        })
    },[]);
    // Función mostrar certificados y/o mensajes de error
    const CertificateActions = ({canCertificate, course, certificationData}) => {
        let firstButton, secondButton;

        if(canCertificate || course['user_study.users_studies_certification.certificationId']!=null) {
            firstButton = <CourseActionButton {...certificationData.buttonProps} course={course}></CourseActionButton>;
            secondButton = <CopyLinkToClipboard course={course} link={certificationData.certificateUrls.certificationViewUrl}></CopyLinkToClipboard>;
        } else {
            firstButton = <div className={`col-12 ProfilePage__download-certificate-button ProfilePage__download-certificate-button--disabled-green`} >
                            <span>No disponible en tu Plan</span>
                          </div>;

            secondButton = <Link to='/planes' 
                                className={`col-12 ProfilePage__copy-link-button`}>
                                <i className="fa-solid fa-credit-card"></i> <span className='ms-2'>Ir a Planes</span>
                            </Link>;
        };

        return (
                <>
                    <div className='row' style={{marginBottom: "5px"}}>
                        <div className='ProfilePage__container-button'>
                            {firstButton}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='ProfilePage__container-button'>
                            {secondButton}
                        </div>
                    </div>
                </>
                );
    }
    // Función descargar certificados con plan gratuito y/o mensajes de error
    const CertificateFreeActions = ({canCertificate, course, certificationData}) => {
        let firstButton;
        if(canCertificate || course['user_study.users_studies_certification.certificationId']!=null) {
            firstButton = <CourseActionButton {...certificationData.buttonProps} course={course}></CourseActionButton>;
        } else {
            firstButton = <div className={`col-12 ProfilePage__download-certificate-button ProfilePage__download-certificate-button--disabled-green`} >
                            <span>No disponible en tu Plan</span>
                          </div>;
        };
        return (
                <div className='row' style={{marginBottom: "5px"}}>
                    <div className='ProfilePage__container-button'>
                        {firstButton}
                    </div>
                </div>
                );
    }
    // Handle para cambiar textos en inputs dentro del modal de encuesta
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };
    // Handle para cambiar selección de checkbox en modal de encuesta
    const handleCheckboxChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: parseInt(value),
        });
    };
    // Handle para cerrar modal de encuesta
    const handleCloseModal = () => {
        setShowModalSurvey(false);
    }
    // Validar que todos los campos requeridos estén completos
    const validateForm = () => {
        if (
          userData.SimpleRegister !== null &&
          userData.ExperiencePlatform !== null &&
          userData.FulfillmentExpectations !== null &&
          userData.Recommendation !== null &&
          userData.Comments !== ''
        ) {
            if(userData.FulfillmentExpectations < 5 && userData.FulfillmentComments === ''){
                setShowErrors(true);
                return false;
            }
            setShowErrors(false);
            return true;
        } else {
          setShowErrors(true);
          return false;
        }
      };
    // Handle para enviar datos de encuesta de curso antes de generar certificado
    const handleResponseSurvey = async (event) => {
        event.preventDefault();
        let validate = validateForm();
        if(validate){
            // Handle para llenar encuesta al generar certificados
            await handleFetch({
                url: 'certifications/surveys',
                method: 'POST', 
                headers: {Authorization: `Bearer ${authToken}`},
                data: userData
                }).then(cert => {
                    setShowToast({show:true, type:'success', message: `<span>Se ha guardado tu información de la encuesta para el curso <strong>${surveyCourse.name}</strong>.</span>`});
                }).catch(()=> {
                    setShowToast({show:true, type:'error', message: `<span>Ocurrió un error llenando la encuesta para el curso <strong>${surveyCourse.name}</strong>. Intenta nuevamente.</span>`});
                })  
            setShowModalSurvey(false);
            if(checkPlan && checkPlan?.planPrice !== 0){
                if(surveyCourse['attr.type']==1){
                    generateCertificate(surveyCourse)
                }else{
                    generateBadge(surveyCourse)
                }
            }else{
                downloadFreeCertificate(surveyCourse)
            }
        }
    }
    return(
        <>
        <Modal
                open={showModalSurvey}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleResponseSurvey}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12 align-items-center'>
                                <div className='AcceptDialog__body'>Encuesta EanX</div>
                                <div className='text-center mb-3'>{nameCourseSurvey}</div>
                                    <div className='text-center mt-1 B2BAdmin__text-description'>¿Qué tan sencillo fue el proceso de registro? (1-10)</div>
                                    <div className='text-center mb-2'>{Array.from({ length: 10 }, (_, index) => (
                                    <label className='B2BAdmin__text-description ProfilePage__label_survey' key={index}>
                                        {index + 1}
                                        <input
                                        className='ProfilePage__input_survey'
                                        type="checkbox"
                                        name="SimpleRegister"
                                        value={index + 1}
                                        checked={userData.SimpleRegister === index + 1}
                                        onChange={handleCheckboxChange}
                                        />{' '}
                                    </label>
                                    ))}</div>
                                    {!userData.SimpleRegister && showErrors && (
                                        <span role="alert" className='fs-6 auth__inputError'>
                                            Debes seleccionar una opción
                                        </span>
                                    )}
                                    <div className='text-center mt-1 B2BAdmin__text-description'>¿Qué tan positiva fue su experiencia de navegación y uso dentro de la plataforma? (1-10)</div>
                                    <div className='text-center mb-2'>{Array.from({ length: 10 }, (_, index) => (
                                    <label className='B2BAdmin__text-description ProfilePage__label_survey' key={index}>
                                        {index + 1}
                                        <input
                                        className='ProfilePage__input_survey'
                                        type="checkbox"
                                        name="ExperiencePlatform"
                                        value={index + 1}
                                        checked={userData.ExperiencePlatform === index + 1}
                                        onChange={handleCheckboxChange}
                                        />{' '}
                                    </label>
                                    ))}</div>
                                    {!userData.ExperiencePlatform && showErrors && (
                                        <span role="alert" className='fs-6 auth__inputError'>
                                            Debes seleccionar una opción
                                        </span>
                                    )}
                                    
                                    <div className='text-center mt-1 B2BAdmin__text-description'>Del curso que inscribió/finalizó, ¿cuánto cumplió con sus expectativas? (1-5)</div>
                                    <div className='text-center mb-2'>{Array.from({ length: 5 }, (_, index) => (
                                    <label className='B2BAdmin__text-description ProfilePage__label_survey' key={index}>
                                        {index + 1}
                                        <input
                                        className='ProfilePage__input_survey'
                                        type="checkbox"
                                        name="FulfillmentExpectations"
                                        value={index + 1}
                                        checked={userData.FulfillmentExpectations === index + 1}
                                        onChange={handleCheckboxChange}
                                        />{' '}
                                    </label>
                                    ))}</div>
                                    {!userData.FulfillmentExpectations && showErrors && (
                                        <span role="alert" className='fs-6 auth__inputError'>
                                            Debes seleccionar una opción
                                        </span>
                                    )}

                                    { userData.FulfillmentExpectations!==null && userData.FulfillmentExpectations < 5 && <><div className='text-center mt-1 B2BAdmin__text-description'>¿Por qué?</div>
                                    <div className='text-center mb-2'>
                                        <textarea
                                            className='form-control PlanFormContainer__input'
                                            name="FulfillmentComments"
                                            value={userData.FulfillmentComments}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {!userData.FulfillmentComments && userData.FulfillmentExpectations < 5 && showErrors && (
                                        <span role="alert" className='fs-6 auth__inputError'>
                                            Debes llenar este campo
                                        </span>
                                    )} 
                                    </>
                                    }
                                    <div className='text-center mt-1 B2BAdmin__text-description'>¿Cuánto recomienda la plataforma EanX? (1-5)</div>
                                    <div className='text-center mb-2'>{Array.from({ length: 5 }, (_, index) => (
                                    <label className='B2BAdmin__text-description ProfilePage__label_survey' key={index}>
                                        {index + 1}
                                        <input
                                        className='ProfilePage__input_survey'
                                        type="checkbox"
                                        name="Recommendation"
                                        value={index + 1}
                                        checked={userData.Recommendation === index + 1}
                                        onChange={handleCheckboxChange}
                                        />{' '}
                                    </label>
                                    ))}</div>
                                    {!userData.Recommendation && showErrors && (
                                        <span role="alert" className='fs-6 auth__inputError'>
                                            Debes seleccionar una opción
                                        </span>
                                    )}

                                    <div className='text-center mt-1 B2BAdmin__text-description'>Comentario o sugerencia adicional</div>
                                    <div className='text-center mb-2'>
                                        <textarea
                                            className='form-control PlanFormContainer__input'
                                            name="Comments"
                                            value={userData.Comments}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {!userData.Comments && showErrors && (
                                        <span role="alert" className='fs-6 auth__inputError'>
                                            Debes llenar este campo
                                        </span>
                                    )}
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Generar Certificado</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <TakeCourseIndicators 
            showWait={data.loading} 
            showError={errorDialog} 
            errorCloser={()=>setErrorDialog(false)} />
            <div className='row'>
                <div className='col-12'>
                    {coursesDisplayed.length===0 &&
                        <div className="col mx-sm-5">
                            <div className="text-center text_primary my-5" >
                                <span className="fa-stack fa-3x">
                                    <i className="fa-solid fa-graduation-cap fa-stack-1x"></i>
                                    <i className="fa-solid fa-ban fa-stack-2x"></i>
                                </span>                                
                                <p style={{fontSize: "20px"}}>No has completado ningún curso</p>
                            </div>
                        </div>
                    }
                {Object.keys(coursesCertificationData).length!=0 && coursesDisplayed.map((course, index)=> {
                        const certDataKey = course.contentProviderId+'_'+course.courseId+'_'+course['attr.type'];
                        const enableShare = coursesCertificationData[certDataKey].certificateUrls.certificationUrl!=null;


                        const twitterMessage = `Estoy feliz porque terminé el curso ${course.name} en @EanX #EducaciónSinBarreras. No te quedes atrás, aprende y evoluciona (www.eanx.co)` 
                        const whatsappMessage = `¡Cumplí una meta más! Me certifiqué en *${course.name}* con *${course.instructorData.name}*. EanX #EducaciónSinBarreras ` + coursesCertificationData[certDataKey].certificateUrls.certificationShareWhatsappUrl+'.';

                        const twitterLink = `https://twitter.com/intent/tweet/?text=${encodeURIComponent(twitterMessage)}`;
                        const whatsappLink = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;

                        /*return (<div key={course.contentProviderId + '__'+ course.courseId + '__'+ course['attr.type']} className='d-block d-md-flex ProfilePage__course-in-progress-row mb-5'>
                            <div className='d-flex justify-content-center me-md-3 align-items-center'>
                                <img className='ProfilePage__finished-course-image'src={course.imageUrl} onClick={()=>{onCourseRedirection(course)}} />
                            </div>
                            <div className='col-12 col-md-7 col-lg-5 col-xl-5'>
                                <div className='row ProfilePage__container-course-title' >
                                    <span className='ProfilePage__course-title' onClick={()=>{onCourseRedirection(course)}}>{course.title}</span>
                                </div>
                                {checkPlan && checkPlan.planPrice !== 0 ?<>
                                <CertificateActions 
                                    canCertificate={!certificationNotAllowed}
                                    course={course}
                                    certificationData={coursesCertificationData[certDataKey]}
                                >
                                </CertificateActions>
                                <div className='row' style={{marginTop: '8px'}}>
                                    <div className='col-12 ProfilePage__share-buttons-container'>
                                        <span style={{fontSize: '14px', marginRight: '5px'}}>Compártelo </span>
                                        <ShareButton enabled = {enableShare} 
                                                        link = {coursesCertificationData[certDataKey].certificateUrls.certificationShareLinkedInUrl}
                                                        icon = 'linkedin'>
                                        </ShareButton>
                                        <ShareButton enabled = {enableShare} 
                                                        link = {twitterLink}
                                                        icon = 'twitter-square'>
                                        </ShareButton>
                                        <ShareButton enabled = {enableShare} 
                                                        link = {whatsappLink}
                                                        icon = 'whatsapp'>
                                        </ShareButton>
                                    </div>
                                </div></> : 
                            <CertificateFreeActions 
                                canCertificate={!certificationNotAllowed}
                                course={course}
                                certificationData={coursesCertificationData[certDataKey]}
                            />
                            }
                            </div>
                        </div>);*/
                        return (<div key={course.contentProviderId + '__'+ course.courseId + '__'+ course['attr.type']} className='d-block d-md-flex ProfilePage__course-in-progress-row mb-5'>
                            <div className='d-flex justify-content-center me-md-3 align-items-center'>
                                <img className='ProfilePage__finished-course-image'src={course.imageUrl} onClick={()=>{onCourseRedirection(course)}} />
                            </div>
                            <div className='col-12 col-md-7 col-lg-5 col-xl-5'>
                                <div className='row ProfilePage__container-course-title' >
                                    <span className='ProfilePage__course-title' onClick={()=>{onCourseRedirection(course)}}>{course.title}</span>
                                </div>
                                <CertificateFreeActions 
                                    canCertificate={!certificationNotAllowed}
                                    course={course}
                                    certificationData={coursesCertificationData[certDataKey]}
                                />
                            </div>
                        </div>);
                        }           
                    )}
                </div>
            </div>
            {coursesDisplayed.length>0 &&
            <div className='row mb-3'>
                <div className='col-12 d-flex justify-content-center'>
                    <Pagination size='small' count={numberOfPages} onChange={handleChangePage} />
                </div>
            </div>
            }
            <Toast 
                show={showToast.show}
                message={showToast.message}
                type={showToast.type}
                closeCallback={toastReset}
            >
            </Toast>
            <Dialog  open={showUnavailableDialog.show} 
                    classes={{paper:'AcceptDialog__container'}} 
                    disableScrollLock={ true }
                    onClose={closeUnavailbleDialog}>
                <div>
                    <div className='text-center ProfilePage__modal-icon ProfilePage__modal-icon--blue '>
                        <i className="fa-regular fa-circle-xmark fa-3x"></i>
                    </div>

                    <div className="text-center px-3">
                        <div className='ProfilePage__modal-title'>Ha ocurrido un error</div>
                        <div>
                            <p>
                            Ocurrió un error con la generación del certificado para el curso <br/> 
                            <strong>{showUnavailableDialog.courseName}</strong>
                            </p>
                            <p>
                                <Link className='text_primary' to='/contactanos'>Haz click aquí</Link>  para enviar un mensaje a soporte técnico y en las próximas 72 horas nos pondremos en contacto contigo.
                            </p>
                        </div>
                        <div className='ProfilePage__modal-buttons-container'>
                            <button className='ProfilePage__modal-button ProfilePage__modal-button--highlight' 
                                    onClick={closeUnavailbleDialog}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog  open={showNoPlanDialog.show} 
                    classes={{paper:'AcceptDialog__container'}} 
                    disableScrollLock={ true }
                    onClose={closeNoPlanDialog}>
                <div>
                    <div className='text-center ProfilePage__modal-icon ProfilePage__modal-icon--blue mt-2'>
                    <i className="fa-solid fa-award fa-3x"></i>                               
                    </div>
                    <div className="text-center px-3">
                        <div className='ProfilePage__modal-title'>Generar certificado</div>
                        <div>
                            Para poder generar el certificado del curso <strong>{showNoPlanDialog.courseName}</strong> debes estar suscrito a alguno de nuestros planes
                        </div>
                        <div className='ProfilePage__modal-buttons-container'>
                            <Link className='ProfilePage__modal-button ProfilePage__modal-button--highlight' to='/planes'>
                                Ver Planes
                            </Link>
                            <button className='ProfilePage__modal-button' 
                                    onClick={closeNoPlanDialog}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
