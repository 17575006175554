import React, { useEffect, useState } from 'react';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import '../../../styles/components/controls/UIControls.scss'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado de beneficions en backoffice
 * @param {string} setBenefitsGetter Listado de beneficios
 * @param {string} benefits Beneficio agregado
 * @function PlanBenefitsList
 */
//
export const PlanBenefitsList = ({setBenefitsGetter, benefits=null}) => {
    
    const [benefitsList, setBenefitsList] = useState([])

    useEffect(()=>{
        if(setBenefitsGetter) {
            setBenefitsGetter({getBenefits:getBenefitsInfo});
        }
        if(benefits){
            const benefitsListAux = [];
            
            for (let index = 0; index < benefits.length; index++) {
                benefitsListAux.push(index.toString())                
            }
            
            setBenefitsList(benefitsListAux)
        }else{
            setBenefitsList(['0'])
        }
    },[benefits]);


    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      );

    const [benefistIndex, setbenefistIndex] = useState((benefitsList.length-1).toString())

    const handleDragEnd = (event) =>{

        const {active, over} = event;
        
        if (active.id !== over.id) {
            setBenefitsList((benefitsList) => {
            const oldIndex = benefitsList.indexOf(active.id);
            const newIndex = benefitsList.indexOf(over.id);
            
            return arrayMove(benefitsList, oldIndex, newIndex);
          });
        }
      }
    // Función Agregar beneficios
    const handleAddBenefit = () => {
        let auxArray = [...benefitsList]
        auxArray.push((parseInt((benefitsList.length)+1).toString()))

        setBenefitsList(auxArray)
        setbenefistIndex((parseInt(benefistIndex)+1).toString())
    }
    // Función Listar beneficios
    const getBenefitsInfo = () =>{
        let benefitsInfoArr = []
        const benefitsDOMList = document.getElementById('PlanBenefitList').children
        for (let index = 0; index < benefitsDOMList.length; index++) {
            benefitsInfoArr.push({
                'title':benefitsDOMList[index].children[1].children[0].value,
                'position':index,
                'available': benefitsDOMList[index].children[1].children[1].children[0].children[1].checked
            });
        }
        return benefitsInfoArr
        
    }
    // Función Eliminar beneficios
    const handleRemoveBenefit = (e) => {
        let auxArray = [...benefitsList]
        setBenefitsList(auxArray.filter((i)=>{return(i!==e);}));
    }

    return (
        <div className='mb-3'>
            <label className="form-label PlanFormContainer__label">Beneficios</label>
            <div className='PlanFormContainer__group'>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext 
                        items={benefitsList}
                        strategy={verticalListSortingStrategy}
                        id='PlanBenefitList'
                    >
                        <div id='PlanBenefitList'>
                        {benefitsList.map(id => 
                                            <SortablePlanBenefitElement 
                                                key={`item-${id}`} 
                                                benefit={benefits[parseInt(id)]?benefits[parseInt(id)]:null}
                                                value={id}
                                                id={id}
                                                lastOne={benefitsList.length===1} 
                                                handleRemoveBenefit={handleRemoveBenefit}
                                                handle={true}
                                                removable={true}
                                            />
                                    )}
                        </div>

                    </SortableContext>
                </DndContext>
                <div className='text-center'>
                    <button type='button' className='col-10 ActionButton my-3' onClick={handleAddBenefit}>
                        + Agregar beneficio
                    </button>
                </div>
            </div>
        </div>

    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Ordenar beneficios
 * @param {event} handleRemoveBenefit Evento para eliminar beneficio
 * @param {string} value Valor recibido
 * @param {string} lastOne Último primer beneficio
 * @param {string} id Id del plan
 * @param {string} benefit Valor del beneficio
 * @function SortablePlanBenefitElement
 */
const SortablePlanBenefitElement = (({handleRemoveBenefit, value, lastOne,id, benefit=null}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        touchAction: 'none',
        transition,
    };
    return (
        <div ref={setNodeRef}  style={style} className="d-flex align-items-center PlanFormContainer__plan-benefit">
            <div className="col-1 d-flex">
                <div {...attributes} {...listeners} className="PlanFormContainer__benefit-button me-auto">
                    <i className="fa-solid fa-equals"></i>
                </div>
            </div>
            <div className="col-10 offset-1 offset-sm-0 col-sm-11">
                <textarea id={`item-${value}-title`} className="form-control PlanFormContainer__input mb-2" defaultValue={benefit? benefit.title:null}></textarea>
                <div className="d-flex align-items-center">
                    <div className="form-check form-switch me-auto">
                        <label className="form-check-label">Disponible</label>
                        <input id={`item-${value}-available`} className="form-check-input PlanFormContainer__check-input" type="checkbox" defaultChecked={benefit? benefit.available:true}></input>
                    </div>
                    <button type='button'
                            disabled={lastOne}
                            className={`PlanFormContainer__benefit-button ${lastOne?'PlanFormContainer__benefit-button--delete-lastone': 'PlanFormContainer__benefit-button--delete'}`}
                            onClick={()=>{handleRemoveBenefit(value)}}
                    >
                        <i className="fa-solid fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    )
})
