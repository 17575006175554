import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useFetch } from '../../hooks/useFetch';
import { MessageDialog, AuthFormContainerPage } from '../controls/UIControls';
import { useNavigate } from 'react-router-dom';
import * as CONSTANTS from '../../assets/constants';
import { useParams } from 'react-router-dom';
import { RegisterPage } from '../register/RegisterPage';
import { PlanPage } from '../plan/PlanPage';
/**
 * Creado por Equilibrio Agency 2024<br>
 * Función para mensaje de error
 * @param {props} props Contenido para mensaje de error (className, message)
 * @function ErrorMessage
 */
const ErrorMessage = (props) => {
    return (
        <div className={`auth__errorMessage ${props.className}`}>
            <span role="alert" dangerouslySetInnerHTML={{ __html: props.message }}>
            </span>
        </div>
    )
}
/**
 * Creado por Equilibrio Agency 2024<br>
 * Función de búsqueda de código QR para registro de usuarios
 * @param {props} props Props utilizados para el formulario de búsqueda
 * @function SearchUserForm
 */
const SearchQRUserForm =  (props) => {
    const navigate = useNavigate();
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;
    const alphanumericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [fetchData, handleFetch] = useFetch();
    const [dialogProperties, setDialogProperties] = useState({open:false});
    const navigator = useNavigate();
    //Función de retorno a sección de contacto si un estudiante no aparece en base de datos
    const afterCloseErrorDialog = () => {
        setDialogProperties({open:false});
        navigate("/contactanos", { state: { eanuser: {subject: "Mi Cuenta EanX", message: "Tengo un código y no puedo registrarme"} } });
    }
    // Handle para consulta si existe usuario eanista, recibe por parámetro número de documento
    const handleSearchUser = (formValues) => {
        const response = handleFetch({
            url: `users/couponsusers?code=${formValues.codeqr}`,
            method: 'GET',
        });
        response
            .then((r) => {
                    if(r?.code){
                        navigator('/qr/'+r.code)
                    }
                },
                error => {
                    setDialogProperties({open:true,
                        title: 'Código NO Válido ',
                        message:`El código que ingresaste NO es válido<br>` +
                            `ingresa correctamente el código para continuar tu registro.`,
                        messagethree: 'No tengo un código y',
                        messagelink: 'quiero adquirir un plan',
                        link: '/planes',
                        afterClose: afterCloseErrorDialog,
                        button_text: 'Ponerme en Contacto con EanX',
                        showCloseButton:true,
                        cancelClose: ()=>setDialogProperties({open:false}),
                        style:'error',
                    });
                })
    }
    return (
        <>
        <MessageDialog {...dialogProperties}></MessageDialog>
        <form onSubmit={ handleSubmit(handleSearchUser) }>
            <div className="mb-1">
                <label className='auth__form-label'>Código Para Registro</label>
                <input
                    type='text'
                    name='codeqr'
                    className={`form-control auth__input 
                                ${errors.codeqr?"auth__input--error":""}`}
                    placeholder={CONSTANTS.LABEL_QRCODE_DISCOUNT_PLACEHOLDER}
                    {...register("codeqr", {
                        required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED,
                        pattern: {
                            value: alphanumericPattern,
                            message: alphanumericMessage
                        } })}
                />
                {errors.codeqr && (
                    <span role="alert" className='auth__inputError'>
                    {errors.codeqr.message}
                    </span>
                )}
                <ErrorMessage />
            </div>
            <div>
                <span style={{fontSize: "14px", etterSpacing: "-1%", textAlign: "justify"}}>
                    <input type="checkbox" value="" {...register("acceptPolicy", {required:CONSTANTS.MESSAGE_ACCEPT_POLICY_REQUIRED})}/> Tengo a disposición la <a href='https://universidadean.edu.co/sites/default/files/institucion/acuerdos/politica-tratamiento-de-datos-personales.pdf' className='auth__link'>Política de tratamiento de datos personales</a>&nbsp;
                    y el <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/aviso-de-privacidad' className='auth__link'>Avíso de privacidad</a> de la Universidad Ean
                    y con fundamento en ello, <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/autorizacion-para-uso-de-datos-personales' className='auth__link'>autorizo</a> a la Universidad Ean de manera voluntaria, explícita, informada e inequívoca el
                    tratamiento de mis datos personales.</span>
            </div>
            {errors.acceptPolicy && (
                <div role="alert" className='auth__inputError'>
                    {errors.acceptPolicy.message}
                </div>
            )}

            <button
                type="submit"
                className={`mb-1 auth__btn-form`}
            >
                <span>Enviar</span>
            </button>
            <button className='ActionButton ActionButton--secondary' onClick={()=>{navigate('/')}}>Volver al inicio</button>
        </form>
    </>
    )
}
/**
* Creado por Equilibrio Agency 2024<br>
* Página verificación de usuario
* @param {string} setAuthToken Token de sesión
* @return {HTMLElement} Elementos HTML de cada componente
* @interface IdVerificationPage
*/
export const IdQRVerificationPage = ({setAuthToken}) => {
    const searchUserForm = (<>
        <SearchQRUserForm setAuthToken={ setAuthToken } />
    </>)
    return (
        <>
            <AuthFormContainerPage title="Registro QR" content={searchUserForm}></AuthFormContainerPage>
        </>
    )
}
export const DirectRegisterQR = () => {
    const { code } = useParams();
    const [dataPlan, setDataPlan] = useState(undefined);
    const [data, handleFetch] = useFetch();
    const navigator = useNavigate();
    // useEffect para consultar cupón QR existente
    useEffect(()=> {
        handleFetch({
          url: `users/couponsusers?code=${code}`,
          method: 'GET'
        })
        .then (async r => {
            if(r?.code){
                setDataPlan({ id: r.plan.id, name: r.plan.name, price: r.plan.price, duration: r.plan.duration, durationPeriod: r.plan.durationPeriod, type: r.plan.type, code: r.code, codeId: r.id })
            }else{
                navigator('/planes')
            };
        })
        },[code]);
    return (
        <div>
            { dataPlan!=undefined ? <RegisterPage dataPlan={dataPlan} /> : <PlanPage /> }
        </div>
    )
}