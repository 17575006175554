import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página contenido de sección usuarios
 * @interface ReportsDetailSection
 */
export const ReportsDetailSection = ({users}) => {
    // Header para historial de pagos
    const headers = [
        {
            "name":"FECHA PAGO",
            "width": "12%"
        },
        {
            "name":"CÓDIGO OPERACIÓN",
            "width": "20%"
        },
        {
            "name": "FECHA INICIO",
            "width":"12%"
        },
        {
            "name": "FECHA FIN",
            "width":"12%"
        },
        {
            "name":"PLAN/CURSO ADQUIRIDO",
            "width": "20%"
        },
        {
            "name":"MONTO",
            "width": "12%"
        },
        {
            "name":"ESTADO",
            "width": "12%"
        }
    ];
    // Función para mostrar estado de cada plan contratado
    const documentType = (documentType) => {
        let status = '';
        switch(documentType) {
            case 'CC':
                status = "Cédula de Ciudadanía";
                break;
            case 'CE':
                status = "Cédula de Extranjería";
                break;
            case 'DIE':
                status = "Documento de Identidad Extranjera";
                break;
            case 'PA':
                status = "Pasaporte";
                break;
            case 'TI':
                status = "Tarjeta de Identidad";
                break;
            case 'PEP':
                status = "Permiso Especial de Permanencia";
                break;
        }
        return status;
    }

    return (
        <>
            <Link to='/admin/facturacion' className='px-4 px-sm-5 text_primary fw-bold'>Volver a Facturación</Link>

            <div className='text-center'><h1>Detalles de operación</h1> </div>
            <div className='d-flex justify-content-center'>
                <div className='UserFormContainer row m-2 p-3 col-10 col-md-10'>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Nombre Completo</label>
                                    <label className="form-label UserFormContainer__sublabel">{users['webuser.name']} {users['webuser.lastname']}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Correo electrónico</label>
                                    <label className="form-label UserFormContainer__sublabel">{users['webuser.email']}</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Tipo de documento</label>
                                    <label className="form-label UserFormContainer__sublabel">{documentType(users['webuser.documentType'])}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Número de documento</label>
                                    <label className="form-label UserFormContainer__sublabel">{users['webuser.document']}</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Teléfono</label>
                                    <label className="form-label UserFormContainer__sublabel">{users['webuser.phone']}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Dirección</label>
                                    <label className="form-label UserFormContainer__sublabel">{users['webuser.address']}</label>
                                </div>
                            </div>
                        </div>
                        <div className='' style={{width: "100%", marginTop: "17px", overflowX: "auto"}}>
                        <div className='mb-3'>
                                <table className='UserAdmin__table' >
                                    <thead className='UserAdmin__subtable-header' style={{height: "52px"}}>
                                    <tr style={{cursor: "pointer"}}>
                                        {headers.map((header, index)=>{
                                            return(
                                                <th key={index} style={{width: header.width, paddingLeft: "16px", textAlign: "center"}}>{header.name}</th>
                                            )
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={`UserAdmin__table-row UserAdmin__table-pair-row`}>
                                            <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(users.createdAt).format('DD/MM/YYYY')}</td>
                                            <td style={{paddingLeft: "16px", textAlign: "center"}}>{users.approvalCode || users.requestId}</td>
                                            <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(users.startsAt).format('DD/MM/YYYY')}</td>
                                            <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(users.endsAt).format('DD/MM/YYYY')}</td>
                                            <td style={{paddingLeft: "16px", textAlign: "center"}}>{users['plan.title'] ? users['plan.title'] : users['course.title']}</td>
                                            <td style={{paddingLeft: "16px", textAlign: "center"}}>{users.paidPrice || '-'} COP</td>
                                            <td style={{paddingLeft: "16px", textAlign: "center"}}>Completado</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                        </div>
                </div>
            </div>
        </>
    );
}