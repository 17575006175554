import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import * as CONSTANTS from '../../../assets/constants';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Toast } from '../../controls/UIControls';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import { apiConfig } from '../../../api/apiConfig';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Gestión de cursos para B2B
 * @interface B2BPlandCourseSection
 */
export const B2BPlandCourseSection = () => {

    const [users, setUsers] = useState([]);
    const [data, handleFetch] = useFetch();
    const location = useLocation();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const navigate = useNavigate();

    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const [dataFilter, setDataFilter] = useState({
        identificationNumber: '',
        email: '',
        lastname: '',
        name: '',
    })
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || { show: false });
        window.history.replaceState(null, '');
    }, [])
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <Link to={`/admin/planes`} className='px-4 px-sm-5 text_primary fw-bold'>Volver a Planes</Link>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Cursos Pagos</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>

                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable users={users} dataFilter={dataFilter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ users, dataFilter }) => {

    const [page, setPage] = useState(0);
    const [totalt, setTotalt] = useState(0);
    const [b2b, setB2b] = useState([]);
    const [courses, setCourses] = useState([]);
    const [fullcatalog, setFullcatalog] = useState([]);
    const [planSelected, setPlanSelected] = useState(1);
    const [pricecourse, setPricecourse] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [viewActive, setViewActive] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [filtro, setFiltro] = useState('');
    const handleInputChange = (e) => {
        setFiltro(e.target.value);
    };

    const refreshCatalog = () => {
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        const responsech = handleFetch({
            url: `bo/courseondemand?$limit=50&companyId=${planSelected}`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responsech
            .then((r) => {
                setCourses(r.data)
                setTotalt(r.total)
                let newArray = fullcatalog.filter(item1 => {
                    return !r.data.some(item2 => item1.title == item2['course.title'] && item2.planId == planSelected);
                });
                setFullcatalog(newArray);
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    }
    const refreshB2B = () => {
        // consulta de endpoint para los B2B disponibles
        const responseb2b = handleFetch({
            url: `bo/companiesb2b`,
            method: 'GET',            
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responseb2b
            .then((r) => {
                setB2b(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    //Catálogo de cursos existentes en learnworlds y revisar los añadidos en Plan
    const catalogFull = () => {
        const responsecatalog = handleFetch({
            url: `catalog?$limit=500&$skip=0`,
            method: 'GET',
        });
        responsecatalog
            .then(async (r) => {
                const responsech = handleFetch({
                    url: `bo/courseondemand?companyId=${planSelected}`,
                    method: 'GET',
                    headers: {Authorization: `Bearer ${authToken}`}
                });
                responsech
                    .then((rc) => {
                        setCourses(rc.data)
                        let newArray = r.data.filter(item1 => {
                            return !rc.data.some(item2 => item1.title == item2['course.title'] && item2.planId == planSelected);
                        });
                        setFullcatalog(newArray);
                    },
                        error => {
                            console.log(error.errors);
                            if (error.errors) {

                            }
                        })
            },
                error => {
                    console.log(error.errors);
                })
    }
    // Handle para asignar curso
    const handleAssignCourse = async (event) => {
        event.preventDefault();
        setWaiting(true)
        let duration = 0;
        try {
            for (let i = 0; i < selectedCursos.length; i++) {
                fullcatalog.forEach(data => {
                    if (data.courseId == selectedCursos[i]) {
                        duration = data.duration;
                    }
                });
                await handleFetch({
                    url: `bo/courseondemand`,
                    method: 'POST',
                    headers: {Authorization: `Bearer ${authToken}`},
                    data: {
                        companyId: planSelected || 1,
                        courseId: selectedCursos[i],
                        price: pricecourse,
                        canCertificate: true
                    },
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        setToastProperties({
                            show: true,
                            type: 'error',
                            message: 'Error al agregar cursos. Intenta nuevamente.',
                            position: { horizontal: 'left' },
                        });
                    });
            }
        } catch (error) {
            console.error(error);
        }
        setToastProperties({
            show: true,
            type: 'success',
            title: 'Operación exitosa',
            message: 'Se ha añadido el curso exitosamente.',
            position: { horizontal: 'left' },
        });
        setSelectedOption('');
        setFiltro('');
        setViewActive(false);
        setSelectedCursos([]);
        refreshCatalog();
        setWaiting(false)
        setShowModalUpdated(false);
    }
    // Handle para eliminar cursos en lote
    const handleDeleteCourseLote = async (event) => {
        event.preventDefault();
        setWaiting(true)
        try {
            for (let i = 0; i < selectedDeleteCursos.length; i++) {
                await handleFetch({
                    url: `bo/courseondemand/${selectedDeleteCursos[i]}`,
                    method: 'DELETE',
                    headers: {Authorization: `Bearer ${authToken}`}
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch(() => {
                        setToastProperties({
                            show: true,
                            type: 'error',
                            message: 'Error al eliminar los cursos pagos. Intenta nuevamente.',
                            position: { horizontal: 'left' },
                        });
                    });
            }
        } catch (error) {
            console.error(error);
        }
        setToastProperties({
            show: true,
            type: 'success',
            title: 'Operación exitosa',
            message: 'Se han eliminado los cursos pagos Exitosamente.',
            position: { horizontal: 'left' },
        });
        setSelectedOption('');
        setViewActive(false);
        catalogFull();
        setSelectedDeleteCursos([]);
        refreshCatalog();
        setFiltro('');
        setWaiting(false)
        setShowModalDelete(false);
    }
    const compararCursos = (a, b) => {
        var tituloA = a?.['course.title'].toUpperCase();
        var tituloB = b?.['course.title'].toUpperCase();
        if (tituloA < tituloB) {
            return -1;
        }
        if (tituloA > tituloB) {
            return 1;
        }
        return 0;
    };
    const compararFullCatalog = (a, b) => {
        var tituloA = a?.title.toUpperCase();
        var tituloB = b?.title.toUpperCase();
        if (tituloA < tituloB) {
            return -1;
        }
        if (tituloA > tituloB) {
            return 1;
        }
        return 0;
    };

    //Ordenar array de cursos agregados
    const ordenarCourses = () => {
        let arrayOrdenado = [...courses].sort(compararCursos);
        setCourses(arrayOrdenado)
    };
    // Función para mostrar duración de cursos
    const formatDuration = (duration) => {
        if (duration == null) return null;
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        return `${hours}h ${minutes}m`;
    }
    const handleCloseModal = () => {
        setFiltro('');
        setSelectedOption('');
        setViewActive(false);
        setShowModalUpdated(false);
    }
    // Función cerrar modal en caso de eliminar cursos a Plan
    const handleCloseDeleteModal = () => {
        setFiltro('');
        setSelectedOption('');
        setViewActive(false);
        setShowModalDelete(false);
    }
    //Ordenar array del catálogo completo de cursos
    const ordenarFullcatalog = () => {
        let arrayOrdenado = [...fullcatalog].sort(compararFullCatalog);
        setFullcatalog(arrayOrdenado)
    };

    // Función mostrar modal en caso de agregar curso a Plan
    const handleOpenModal = async () => {
        setFiltro('');
        setSelectedOption('');
        setViewActive(false);
        ordenarFullcatalog();
        setSelectAll(false);
        setShowModalUpdated(true);
    }
    const [selectedCursos, setSelectedCursos] = useState([]);
    const handleCursoChange = (cursoId) => {
        setSelectedCursos((prevSelectedCursos) => {
            if (prevSelectedCursos.includes(cursoId)) {
                return prevSelectedCursos.filter((id) => id !== cursoId);
            } else {
                return [...prevSelectedCursos, cursoId];
            }
        });
    };
    const [selectAll, setSelectAll] = useState(false);
    const handleSelectAll = () => {
        let updatedItems = fullcatalog.map((item) => (item?.courseId));
        setSelectedCursos(selectAll != true ? updatedItems : []);
        setSelectAll(!selectAll);
    };
    const [selectDeleteAll, setSelectDeleteAll] = useState(false);
    const handleSelectDeleteAll = () => {
        let updatedDeleteItems = courses.map((item) => (item?.id));
        setSelectedDeleteCursos(selectDeleteAll != true ? updatedDeleteItems : []);
        setSelectDeleteAll(!selectDeleteAll);
    };
    const [selectedDeleteCursos, setSelectedDeleteCursos] = useState([]);
    const handleDeleteCursoChange = (cursoId) => {
        setSelectedDeleteCursos((prevSelectedDeleteCursos) => {
            if (prevSelectedDeleteCursos.includes(cursoId)) {
                return prevSelectedDeleteCursos.filter((id) => id !== cursoId);
            } else {
                return [...prevSelectedDeleteCursos, cursoId];
            }
        });
    };
    // Función mostrar modal en caso de eliminar cursos a Plan
    const handleOpenModalDelete = async () => {
        ordenarCourses();
        setFiltro('');
        setSelectDeleteAll(false);
        setShowModalDelete(true);
    }
    // Header para historial de cursos
    const headercourses = [
        {
            "name": "CREACIÓN INICIO",
            "width": "15%"
        },
        {
            "name": "NOMBRE DEL CURSO",
            "width": "30%"
        },
        {
            "name": "ID B2B",
            "width": "10%"
        },
        {
            "name": "NOMBRE B2B",
            "width": "20%"
        },
        {
            "name": "PRECIO",
            "width": "15%"
        },
        {
            "name": "ACCIONES",
            "width": "10%"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(totalt)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))
    const [toastProperties, setToastProperties] = useState({ show: false });

    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }

    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(totalt)
        refreshB2B()
        refreshCatalog()
        catalogFull()
    }, [planSelected])

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.identificationNumber !== '' ? "&identificationNumber=" + dataFilter.identificationNumber : ''
        let url = `bo/courseondemand?companyId=${apiConfig.company}${filtername}${filterlastname}${filteremail}${filterdocument}&$skip=${result}`
        setPage(event.selected)
        const response = handleFetch({
            url: url,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    const handleDeleteCourse = (id, event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/courseondemand/${id}`,
            method: 'DELETE',
            headers: {Authorization: `Bearer ${authToken}`}
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha eliminado el curso pago Exitosamente.',
                    position: { horizontal: 'left' },
                });
                catalogFull();
                refreshCatalog();
                setShowModalUpdated(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al eliminar el curso pago. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalUpdated(false);
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setPlanSelected(event.target.value); 
        catalogFull();
        refreshCatalog();
        event.target.value!='' ? setViewActive(true) : setViewActive(false);        
    };
    const handlePriceChange = (event) => {
        setPricecourse(event.target.value);      
    };
    const cursosFiltrados = fullcatalog.filter((curso) => curso.title.toLowerCase().includes(filtro.toLowerCase()));
    const cursosFiltradosDelete = courses.filter((curso) => curso['course.name'].toLowerCase().includes(filtro.toLowerCase()));
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalUpdated}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleAssignCourse}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Cursos Pagos</div>
                                <div>
                                    <select value={selectedOption} onChange={handleOptionChange} className="form-select auth__select">
                                    <option value="">Seleccione un B2B</option>
                                        {
                                            b2b.length !== 0 &&
                                            b2b.map((data, index)=>{
                                                    return(
                                                        <option value={data?.id} key={data?.id}>{data?.name}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                    {viewActive && <div className='d-flex mt-3'>
                                        <input
                                            type="text"
                                            placeholder="Buscar curso"
                                            className="form-control PlanFormContainer__input"
                                            value={filtro}
                                            onChange={handleInputChange}
                                        />
                                    </div>}
                                    {viewActive && (
                                        <><div className='B2BAdmin__framecourses'>
                                            <div className='d-flex'>
                                                <input
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                /> <label className='mx-2 fw-bold fs-8 B2BAdmin__divcourse'>Seleccionar Todos</label>
                                            </div>
                                            {cursosFiltrados?.map((curso, index) => (
                                                    <div key={curso.id} className='d-flex'>
                                                    <input
                                                        type="checkbox"
                                                        id={curso.courseId}
                                                        checked={selectedCursos.includes(curso.courseId)}
                                                        onChange={() => handleCursoChange(curso.courseId)}
                                                    />
                                                    <label htmlFor={curso.courseId} className='mx-2 fs-8 B2BAdmin__divcourse'>
                                                        {curso.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='mt-3 mb-3'>
                                        <div className='row'>
                                            <div className="col-6">
                                            <label className="form-label auth__form-label">Costo Actual</label>
                                            <div className="input-group">
                                                <input 
                                                    placeholder={CONSTANTS.LABEL_PLAN_PRICE_PLACEHOLDER}
                                                    type="number"
                                                    name="pricecourse"
                                                    onChange={handlePriceChange}
                                                    className={`form-control PlanFormContainer__input}`}
                                                >
                                                </input>
                                                <span className="input-group-text">COP</span>
                                            </div>
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label auth__form-label">Certificable</label>
                                                <select name="canCertificate" onChange={handleInputChange} className="form-select PlanFormContainer__input">
                                                    <option value='true'>SI</option>
                                                    <option value='false'>NO</option>
                                                </select>
                                            </div>
                                            </div>
                                        </div></>
                                    )}
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>Agregar cursos {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={showModalDelete}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleDeleteCourseLote}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Eliminar Cursos Pagos</div>
                                <div>
                                    <select value={selectedOption} onChange={handleOptionChange} className="form-select auth__select">
                                    <option value="">Seleccione un B2B</option>
                                        {
                                            b2b.length !== 0 &&
                                            b2b.map((data, index)=>{
                                                    return(
                                                        <option value={data?.id} key={data?.id}>{data?.name}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                    {viewActive && <div className='d-flex mt-3'>
                                        <input
                                            type="text"
                                            placeholder="Buscar curso"
                                            className="form-control PlanFormContainer__input"
                                            value={filtro}
                                            onChange={handleInputChange}
                                        />
                                    </div>}
                                    {viewActive && (
                                <div className='B2BAdmin__framecourses'>
                                    <div className='d-flex'>
                                        <input
                                            type="checkbox"
                                            checked={selectDeleteAll}
                                            onChange={handleSelectDeleteAll}
                                        /> <label className='mx-2 fw-bold fs-8 B2BAdmin__divcourse'>Seleccionar todos</label>
                                    </div>
                                    {cursosFiltradosDelete.map((curso) => (
                                        <div key={curso.id} className='d-flex'>
                                            <input
                                                type="checkbox"
                                                id={curso?.id}
                                                checked={selectedDeleteCursos.includes(curso.id)}
                                                onChange={() => handleDeleteCursoChange(curso.id)}
                                            />
                                            <label htmlFor={curso.courseId} className='mx-2 fs-8 B2BAdmin__divcourse'>
                                                {curso['course.name']}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                    )}
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>Eliminar cursos {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseDeleteModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>


            <div className='mb-3 mt-4'>
                <div className='row'>
                    <div className="col-12 col-md-6">
                        <label className="form-label PlanFormContainer__label">Cursos inscritos para Planes</label>
                    </div>
                    <div className="col-12 col-md-6 text-end">
                        <Link to={{}} className="FilterAdmin__search-button" onClick={handleOpenModal}>
                            <span>Agregar Cursos a Planes</span>
                        </Link>
                        <Link to={{}} className="FilterAdmin__search-button" onClick={handleOpenModalDelete}>
                            <span>Eliminar Cursos a Planes</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='' style={{ width: "100%", marginTop: "17px", overflowX: "auto" }}>
                <div className='mb-3'>
                    <table className='UserAdmin__table' >
                        <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                            <tr style={{ cursor: "pointer" }}>
                                {headercourses.map((header, index) => {
                                    return (
                                        <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                courses.length !== 0 ?
                                    courses.map((data, index) => {
                                        const planName = b2b.find(
                                            (item) => parseInt(item.id) === parseInt(data?.companyId),
                                        );
                                        return (
                                            <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                <td style={{ paddingLeft: "16px", textAlign: "center" }}>{moment.utc(data?.['attr.createdAt']).format('DD/MM/YYYY')}</td>
                                                <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.['course.title']}</td>
                                                <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.companyId}</td>
                                                <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data['companiesb2b.name']}</td>
                                                <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>COP {data.price}</td>
                                                <td style={{ paddingLeft: "16px", textAlign: "center" }}><Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => handleDeleteCourse(data.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>
                                            </tr>
                                        );
                                    })
                                    : <tr>
                                        <td colSpan="6" style={{ padding: "16px", textAlign: "center" }}><strong>No existen registros</strong></td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                itemsPerPage={50}
                totalElements={totalSorted}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}